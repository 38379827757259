import React from "react"
import { modelFactory } from "@domenicosolazzo/site-designsystem"
import { DataLinks } from "../../.."

const featureItem = modelFactory.getModel({type: "feature_item", options:{}})

const HomePage = {
  hero: modelFactory.getModel({type: "hero_with_media", options: {
    background: {
      color: modelFactory.getModel({type:"color", options: {start: "white", metadata: {property: "bg"}}})
    },
    heading: modelFactory.getModel({type: "text", options: {
      text: "Hi, everyone 👋",
      className: "block pb-4",
      color: modelFactory.getModel({type: "color", options: {
        gradient: true,
        start: "primary-500",
        end: "secondary-500",
        direction: "b"
      }}),
      wrapper:{
        type:"div",
        className: "",
        hidden: false,
      },
      metadata: {
        type: "p"
      }
    }}),
    subheading: modelFactory.getModel({type: "text", options: {
      text: (
        <div>
          {`I am Domenico Solazzo, an engineering manager and software engineer. `}
          <br /><br />
          {`I am passionate about creating digital products, staying healthy and make a positive impact in people's lives.`}
          <br /><br />
          <div>
            {`Currently, I am working as Engineering Manager and leading a software development team.`}
          </div>
          <br /><br/>
          <div>
            {`In the spare time, I am a content creator sharing my life as a developer, talking about 
            software engineering, entrepreneurship and leadership.`}
          </div>
        
        </div>
      ),
      color: modelFactory.getModel({type: "color", options: {
        start: "neutral-500",
      }}),
    }}),
    subtitle: modelFactory.getModel({type: "text", options:{
      text: "Engineering manager & Software Engineer",
      className: "uppercase",
      color: modelFactory.getModel({type: "color", options: {
        start: "quaternary-500"
      }})
    }})
  }}),
  testimonial: modelFactory.getModel({ type: "testimonial_overalapping_image", options: {
    message: modelFactory.getModel({type: "text", options: {
      text: "Having Domenico as my manager was a great experience. He always made sure I had enough work to do and was available to answer any questions I had. He is a very talented manager who has the leadership to take a team to its best, as well as the communication needed to transmit ideas to his colleagues",
      color: modelFactory.getModel({type: "color", options: {
        start: "white"
      }})
    }}),
    background:{
      color: modelFactory.getModel({type: "color", options: {
        start: "primary-500",
        metadata: {
          property: "bg"
        }
      }})
    },
    image:modelFactory.getModel({type: "image", options: {
      src: "https://images.unsplash.com/photo-1546146830-2cca9512c68e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2102&q=80"
    }}),
    author: {
      name: modelFactory.getModel({type: "text", options: {
        text: "Alejandro C.",
        color: modelFactory.getModel({type: "color", options: {
          start: "quaternary-500"
        }})
      }}),
      jobTitle: modelFactory.getModel({type: "text", options: {
        text: "Senior Software Engineer",
        color: modelFactory.getModel({type: "color", options: {
          start: "quaternary-500"
        }})
      }}),
    }
  }}),
  features: modelFactory.getModel({type: "feature", options:{
    background: {
      color: modelFactory.getModel({type: "text", options: {
        start: "white"
      }})
    },
    heading: modelFactory.getModel({type: "text", options:{
      text: "How can I help you?",
      color:  modelFactory.getModel({type: "color", options:{
        start: "quaternary-500"
      }}),
    }}),
    subheading: modelFactory.getModel({type: "text", options:{
      text: `Let me help you building your own solution and leading your software teams.`,
      color: modelFactory.getModel({type: "color", options:{
        start: "neutral-500"
      }}),
    }}),
    sections: [
      modelFactory.clone(featureItem, {
        media: {
          image: modelFactory.getModel({type: "image", options: {
            src: "https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80"
          }})
        },
        heading: modelFactory.getModel({type: "text", options: {
          text: "Engineering Manager",
          color: modelFactory.getModel({type: "color", options: {
            gradient: true,
            start: "primary-500",
            end: "secondary-500",
          }})
        }}),
        subheading: modelFactory.getModel({type: "text", options: {
          text: `I have been leading high performant teams both distributed and on-site. 
          Let me help you building a good engineering culture as well as 
          helping your team members to deliver on-time with quality`,
          color: modelFactory.getModel({type: "color", options: {
            start: "neutral-500"
          }})
        }}),
        itemList: {
          items: [
            modelFactory.getModel({type: "feature_item_item", options: {
              id: 1,
              name: modelFactory.getModel({type: "text", options:{
                text: `High-Performant teams`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "quaternary-500"
                }})
              }}),
              description: modelFactory.getModel({type: "text", options:{
                text: `High-Performant teams will be 10x more productive. `,
                color: modelFactory.getModel({type: "color", options: {
                  start: "neutral-500"
                }})
              }}),
              icon: modelFactory.getModel({type: "icon", options:{
                name: "hero_checkin",
                size: {
                  height: 10
                },
                className: "ml-2 mt-5",
                color: modelFactory.getModel({type: "color", options: {
                  start: "white"
                }})
              }}),
            }}),
            modelFactory.getModel({type: "feature_item_item", options: {
              id: 2,
              name: modelFactory.getModel({type: "text", options:{
                text: `Engineering culture`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "quaternary-500"
                }})
              }}),
              description: modelFactory.getModel({type: "text", options:{
                text: `Having a good engineering culture will help your teams to be both more productive and satisfied.`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "neutral-500"
                }})
              }}),
              icon: modelFactory.getModel({type: "icon", options:{
                name: "hero_checkin",
                size: {
                  height: 10
                },
                className: "ml-2 mt-5",
                color: modelFactory.getModel({type: "color", options: {
                  start: "white"
                }})
              }}),
            }}),
            modelFactory.getModel({type: "feature_item_item", options: {
              id: 3,
              name: modelFactory.getModel({type: "text", options:{
                text: `Mentoring & Coaching`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "quaternary-500",
                }}) 
              }}),
              description: modelFactory.getModel({type: "text", options:{
                text: `Coaching and mentoring is fondamental to help your team members with career development`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "neutral-500"
                }})
              }}),
              icon: modelFactory.getModel({type: "icon", options:{
                name: "hero_checkin",
                size: {
                  height: 10
                },
                className: "ml-2 mt-5",
                color: modelFactory.getModel({type: "color", options: {
                  start: "white"
                }})
              }}),
            }})
          ]
        }
      }),
      modelFactory.clone(featureItem, {
        heading: modelFactory.getModel({type: "text", options: {
          text: "Software Engineer",
          color: modelFactory.getModel({type: "color", options: {
            gradient: true,
            start: "secondary-500",
            end: "primary-500",
          }})
        }}),
        subheading: modelFactory.getModel({type: "text", options: {
          text: `I have been working as software engineer for more than 15 years. Let me help you build your next solution.`,
          color: modelFactory.getModel({type: "color", options: {
            start: "neutral-500"
          }})
        }}),
        media: {
          image: modelFactory.getModel({type: "image", options: {
            src: "https://tailwindui.com/img/features/feature-example-1.png"
          }})
        },
        itemList: {
          items: [
            modelFactory.getModel({type: "feature_item_item", options: {
              id: 1,
              name: modelFactory.getModel({type: "text", options:{
                text: `Web and mobile applications`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "quaternary-500",
                }}) 
              }}),
              description: modelFactory.getModel({type: "text", options:{
                text: `Building mobile and web applications with the last and more performant technologies`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "neutral-500"
                }})
              }}),
              icon: modelFactory.getModel({type: "icon", options:{
                name: "hero_checkin",
                size: {
                  height: 10
                },
                className: "ml-2 mt-5",
                color: modelFactory.getModel({type: "color", options: {
                  start: "white"
                }})
              }}),
            }}),
            modelFactory.getModel({type: "feature_item_item", options: {
              id: 2,
              name: modelFactory.getModel({type: "text", options:{
                text: `Software Architecture`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "quaternary-500",
                }}) 
              }}),
              description: modelFactory.getModel({type: "text", options:{
                text: `System Design and Enterprise architecture for digital products`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "neutral-500"
                }})
              }}),
              icon: modelFactory.getModel({type: "icon", options:{
                name: "hero_checkin",
                size: {
                  height: 10
                },
                className: "ml-2 mt-5",
                color: modelFactory.getModel({type: "color", options: {
                  start: "white"
                }})
              }}),
            }}),
            modelFactory.getModel({type: "feature_item_item", options: {
              id: 3,
              name: modelFactory.getModel({type: "text", options:{
                text: `DevOps & Infrastructure`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "quaternary-500",
                }}) 
              }}),
              description: modelFactory.getModel({type: "text", options:{
                text: `Helping teams to build their DevOps culture as well as having a resilient infrastructure for your solutions`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "neutral-500"
                }})
              }}),
              icon: modelFactory.getModel({type: "icon", options:{
                name: "hero_checkin",
                size: {
                  height: 10
                },
                className: "ml-2 mt-5",
                color: modelFactory.getModel({type: "color", options: {
                  start: "white"
                }})
              }}),
            }})
          ]
        }
      })
    ]
  }}),
  featureTestimonial: modelFactory.getModel({type: "feature", options:{
    heading: modelFactory.getModel({type: "text", options:{
      text: "Content Creator",
      color:  modelFactory.getModel({type: "color", options:{
        start: "quaternary-500"
      }}),
    }}),
    subheading:modelFactory.getModel({type: "text", options:{
      text: "I love creating content and sharing what I know regarding Software Engineering, Leadership and Entrepreneurship",
      color:  modelFactory.getModel({type: "color", options:{
        start: "neutral-500"
      }}),
    }}),
    sections: [
      modelFactory.getModel({type:"feature_item", options: {
        heading: modelFactory.getModel({type: "text", options:{
          text: "Youtube",
          color:  modelFactory.getModel({type: "color", options:{
            gradient: true,
            start: "primary-500",
            end: "secondary-500"
          }}),
        }}),
        subheading: modelFactory.getModel({type: "text", options:{
          text: `Domenico sharing his experience as a software engineer. 
          I am sharing about the life as a developer, software engineering, entrepreneurship and 
          leadership`,
          color:  modelFactory.getModel({type: "color", options:{
            start: "neutral-500"
          }}),
        }}),
        actions: [
          {key:1,
            link: modelFactory.clone(DataLinks.youtube, {linkText: "Watch my videos"})
          }
        ],
        testimonial: null,
        icon: modelFactory.getModel({type: "icon", options: {
          icon:{
            name: "fa-youtube",
            brand: true,
            color: "white",
            size: "2x",
            className: "mt-1 ml-4"
          },
          className: "mt-5 ml-5"
        }}),
        media: {
          image: modelFactory.getModel({type: "image", options: {
            src:"https://images.unsplash.com/photo-1510282271343-fdc3dea55439?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3092&q=80",
          }})
        }
      }}),
      modelFactory.getModel({type:"feature_item", options: {
        heading: modelFactory.getModel({type: "text", options:{
          text: "Instagram",
          color:  modelFactory.getModel({type: "color", options:{
            gradient: true,
            start: "secondary-500",
            end: "primary-500"
          }}),
        }}),
        subheading: modelFactory.getModel({type: "text", options:{
          text: "Instagram is my microblog where I share with you my life as a developer and sharing my knowledge around software engineering. Come and follow me there.",
          color:  modelFactory.getModel({type: "color", options:{
            start: "neutral-500"
          }}),
        }}),
        actions: [
          {key:1,
            link: modelFactory.clone(DataLinks.instagram, {linkText: "Follow me"})
          }
        ],
        testimonial: null,
        metadata: {
          reverse: true
        },
        icon: modelFactory.getModel({type: "icon", options: {
          icon: {
            name: "fa-instagram",
            brand: true,
            color: "white",
            size: "2x",
            className: "mt-1 ml-4"
          },
          className: "mt-5 ml-2"
        }}),
        media: {
          image: modelFactory.getModel({type: "image", options: {
            src:"https://images.unsplash.com/photo-1566137178917-285684132085?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
          }})
        }
      }}),
      modelFactory.getModel({type:"feature_item", options: {
        heading: modelFactory.getModel({type: "text", options:{
          text: "Blog",
          color:  modelFactory.getModel({type: "color", options:{
            gradient: true,
            start: "primary-500",
            end: "secondary-500"
          }}),
        }}),
        subheading: modelFactory.getModel({type: "text", options:{
          text: (
            <div>
              <div>
                {`"Either write something worth reading or do something worth writing." -Benjamin Franklin`}
              </div>
              <br />
              <div>
                {`Writing is a great way to express your my thoughts and sharing what I know to all of you.`}
              </div>
            </div>
            
          ),
          color:  modelFactory.getModel({type: "color", options:{
            start: "neutral-500"
          }}),
        }}),
        actions: [
          {key:1,
            link: modelFactory.clone(DataLinks.blog, {linkText: "Read more"})
          }
        ],
        testimonial: null,
        metadata: {
          reverse: false
        },
        icon: modelFactory.getModel({type: "icon", options: {
          icon: {
            name: "fa-medium",
            brand: true,
            color: "white",
            size: "2x",
            className: "mt-1 ml-4"
          },
          className: "mt-5 ml-2"
        }}),
        media: {
          image: modelFactory.getModel({type: "image", options: {
            src:"https://images.unsplash.com/photo-1519337265831-281ec6cc8514?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
          }})
        }
      }}),
    ]
  }}),
  logoCloud: modelFactory.getModel({type: "logo_cloud", options:{
    heading: modelFactory.getModel({type: "text", options:{
      text: "Where you can find me",
      color:  modelFactory.getModel({type: "color", options:{
        start: "white"
      }}),
    }}),
    background: modelFactory.getModel({type: "color", options: { 
      gradient: true,
      start: "primary-500",
      end: "secondary-500",
      direction: "b",
      metadata: {
        property: "bg"
      }
    }}),
    logos: [
      {
        image: modelFactory.getModel({type: "icon", options: {
          icon: {
            name: "fa-instagram",
            brand: true,
            color: "white",
            size: "4x",
            className: "mt-1 ml-4"
          }, size: {height: 60, width: 60}}}),
        link: DataLinks.instagram
      },
      {
        image: modelFactory.getModel({type: "icon", options: {
          icon: {
            name: "fa-facebook",
            brand: true,
            color: "white",
            size: "4x",
            className: "mt-1 ml-4"
          }, size: {height: 60, width: 60}}}),
        link: DataLinks.facebook
      },
      {
        image: modelFactory.getModel({type: "icon", options: {
          icon: {
            name: "fa-youtube",
            brand: true,
            color: "white",
            size: "4x",
            className: "mt-1 ml-4"
          }, size: {height: 60, width: 60}}}),
        link: DataLinks.youtube
      },
      {
        image: modelFactory.getModel({type: "icon", options: {
          icon: {
            name: "fa-medium",
            brand: true,
            color: "white",
            size: "4x",
            className: "mt-1 ml-4"
          },size: {height: 60, width: 60}}}),
        link: DataLinks.medium
      },
      {
        image: modelFactory.getModel({type: "icon", options: {
          icon: {
            name: "fa-dev",
            brand: true,
            color: "white",
            size: "4x",
            className: "mt-1 ml-4"
          },size: {height: 60, width: 60}}}),
        link: DataLinks.devto
      },
      {
        image: modelFactory.getModel({type: "icon", options: {
          icon: {
            name: "fa-github",
            brand: true,
            color: "white",
            size: "4x",
            className: "mt-1 ml-4"
          },size: {height: 60, width: 60}}}),
        link: DataLinks.github
      },
      {
        image: modelFactory.getModel({type: "icon", options: {
          icon: {
            name: "fa-linkedin",
            brand: true,
            color: "white",
            size: "4x",
            className: "mt-1 ml-4"
          },size: {height: 60, width: 60}}}),
        link: DataLinks.linkedin
      },
      {
        image: modelFactory.getModel({type: "icon", options: {
          icon: {
            name: "fa-twitter",
            brand: true,
            color: "white",
            size: "4x",
            className: "mt-1 ml-4"
          },size: {height: 60, width: 60}}}),
        link: DataLinks.twitter
      }
    ]
  }})
}

export default HomePage