import React from "react"
import { modelFactory } from "@domenicosolazzo/site-designsystem"
import { DataLinks } from "../../.."
import { LogoCloudDataBlog, ContactData } from "../../components"

const PageData = {
  hero:modelFactory.getModel({type: "hero_with_media", options: {
    slogan: modelFactory.getModel({type: "text", options: {
      text: "Software engineer"
    }}),
    heading: modelFactory.getModel({type: "text", options: {
      text: (<p>
        <span className="text-primary-500">I</span> 💖 <span className="text-transparent bg-clip-text text-gradient-to-r from-primary-500  to-secondary-500 text-gradient-to-r from-primary-500  to-secondary-500 text-opacity-100 bg-gradient-to-r from-transparent  to-transparent bg-opacity-100">WRITING! 
        </span>
      </p>),
    }}),
    background: {
      color: modelFactory.getModel({type:"color", options: {start: "white", metadata: {property: "bg"}}})
    },
    subheading: modelFactory.getModel({type: "text", options: {
      text: `Writing is a great way to express your own thoughts. You will find articles about me, my profession, my daily job as well as entrepreneurship`,
      color: modelFactory.getModel({type: "color", options: {
        start: "neutral-500"
      }}),
    }}),
    actions: null,
    media: {
      image: modelFactory.getModel({type: "image", options: {
        src: "https://miro.medium.com/max/1400/1*27GLsjJzMZLCLr7vsFoJPw.png"  
      }})
    }
  }}),
  logoCloud: modelFactory.getModel({type: "logo_cloud", options:{
    heading: modelFactory.getModel({type: "text", options:{
      text: "Where you can find me",
      color:  modelFactory.getModel({type: "color", options:{
        start: "white"
      }}),
    }}),
    background: modelFactory.getModel({type: "color", options: { 
      gradient: true,
      start: "primary-500",
      end: "secondary-500",
      metadata: {
        property: "bg"
      }
    }}),
    logos: [
      {
        image: modelFactory.getModel({type: "icon", options: {
          icon: {
            name: "fa-instagram",
            brand: true,
            color: "white",
            size: "4x",
            className: "mt-1 ml-4"
          }, size: {height: 60, width: 60}}}),
        link: DataLinks.instagram
      },
      {
        image: modelFactory.getModel({type: "icon", options: {
          icon: {
            name: "fa-facebook",
            brand: true,
            color: "white",
            size: "4x",
            className: "mt-1 ml-4"
          }, size: {height: 60, width: 60}}}),
        link: DataLinks.facebook
      },
      {
        image: modelFactory.getModel({type: "icon", options: {
          icon: {
            name: "fa-youtube",
            brand: true,
            color: "white",
            size: "4x",
            className: "mt-1 ml-4"
          }, size: {height: 60, width: 60}}}),
        link: DataLinks.youtube
      },
      {
        image: modelFactory.getModel({type: "icon", options: {
          icon: {
            name: "fa-medium",
            brand: true,
            color: "white",
            size: "4x",
            className: "mt-1 ml-4"
          },size: {height: 60, width: 60}}}),
        link: DataLinks.medium
      },
      {
        image: modelFactory.getModel({type: "icon", options: {
          icon: {
            name: "fa-dev",
            brand: true,
            color: "white",
            size: "4x",
            className: "mt-1 ml-4"
          },size: {height: 60, width: 60}}}),
        link: DataLinks.devto
      },
      {
        image: modelFactory.getModel({type: "icon", options: {
          icon: {
            name: "fa-github",
            brand: true,
            color: "white",
            size: "4x",
            className: "mt-1 ml-4"
          },size: {height: 60, width: 60}}}),
        link: DataLinks.github
      },
      {
        image: modelFactory.getModel({type: "icon", options: {
          icon: {
            name: "fa-linkedin",
            brand: true,
            color: "white",
            size: "4x",
            className: "mt-1 ml-4"
          },size: {height: 60, width: 60}}}),
        link: DataLinks.linkedin
      },
      {
        image: modelFactory.getModel({type: "icon", options: {
          icon: {
            name: "fa-twitter",
            brand: true,
            color: "white",
            size: "4x",
            className: "mt-1 ml-4"
          },size: {height: 60, width: 60}}}),
        link: DataLinks.twitter
      }
    ]
  }}),
  contact: ContactData.getContactInfo({
    items: [
      {title: "Contact", email: `${DataLinks.emails.contact}`, message: "Do you want to work with me, hire me or do you just want to have a chat? Contact me to the email below"},
      {title: "Collaborate", email: `${DataLinks.emails.business}`, message: "Do you want to have a collaboration with me on Youtube, Facebook, Instagram or TikTok?"}
    ]
  }) 
}

export default PageData