import React from "react"
import { modelFactory } from "@domenicosolazzo/site-designsystem"
import { DataLinks } from "../../.."
import { ContactData } from "../../components"

const featureItem = modelFactory.getModel({type: "feature_item", options:{}})

const PageData = {
  hero:modelFactory.getModel({type: "hero_with_media", options: {
    slogan: modelFactory.getModel({type: "text", options: {
      text: "Software engineer"
    }}),
    heading: modelFactory.getModel({type: "text", options: {
      text: (<p>
        <span className="text-primary-500">I</span> 💖 <span className="text-transparent bg-clip-text text-gradient-to-r from-primary-500  to-secondary-500 text-gradient-to-r from-primary-500  to-secondary-500 text-opacity-100 bg-gradient-to-r from-transparent  to-transparent bg-opacity-100">CODING!
        </span>
      </p>),
      wrapper:{
        type:"div",
        className: "",
        hidden: false,
      },
      metadata: {
        type: "p"
      }
    }}),
    background: {
      color: modelFactory.getModel({type:"color", options: {start: "white", metadata: {property: "bg"}}})
    },
    subheading: modelFactory.getModel({type: "text", options: {
      text: `I have been working for more than 15 years as software engineer and I love to build products
       that people enjoy to use.`,
      color: modelFactory.getModel({type: "color", options: {
        start: "neutral-500"
      }}),
    }}),
    actions: null,
    media: {
      image: modelFactory.getModel({type: "image", options: {
        src: "https://images.unsplash.com/photo-1526925539332-aa3b66e35444?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1001&q=80"  
      }})
    }
  }}),
  logoCloud:modelFactory.getModel({type:"logo_cloud", options: {
  
      heading: modelFactory.getModel({type: "text", options:{
        text: "Technologies I have worked with",
        className: "uppercase text-2xl",
        color:  modelFactory.getModel({type: "color", options:{
          start: "white"
        }}),
      }}),
      background: modelFactory.getModel({type: "color", options: { 
        gradient: true,
        start: "primary-500",
        end: "primary-500",
        metadata: {
          property: "bg"
        }
      }}),
      logos: [
        {
          image: modelFactory.getModel({type: "icon", options: {name: "javascript", size: {height: 60, width: 60}}}),
          link: null
        },
        {
          image: modelFactory.getModel({type: "icon", options: {name: "react", size: {height: 60, width: 60}}}),
          link: null
        },
        {
          image: modelFactory.getModel({type: "icon", options: {name: "graphql", size: {height: 60, width: 60}}}),
          link: null
        },
        {
          image: modelFactory.getModel({type: "icon", options: {name: "python", size: {height: 60, width: 60}}}),
          link: null
        },
        {
          image: modelFactory.getModel({type: "icon", options: {name: "csharp", size: {height: 60, width: 60}}}),
          link: null
        },
        {
          image: modelFactory.getModel({type: "icon", options: {name: "java", size: {height: 60, width: 60}}}),
          link: null
        },
        {
          image: modelFactory.getModel({type: "icon", options: {name: "kubernetes", size: {height: 60, width: 60}}}),
          link: null
        },
        {
          image: modelFactory.getModel({type: "icon", options: {name: "docker", size: {height: 60, width: 60}}}),
          link: null
        },
        {
          image: modelFactory.getModel({type: "icon", options: {name: "gcp", size: {height: 60, width: 60}}}),
          link: null
        },
        {
          image: modelFactory.getModel({type: "icon", options: {name: "aws", size: {height: 60, width: 60}}}),
          link: null
        }
      ]
    }
  }),
  features: modelFactory.getModel({type: "feature", options:{
    background: {
      color: modelFactory.getModel({type: "text", options: {
        start: "white"
      }})
    },
    heading: modelFactory.getModel({type: "text", options:{
      text: "SOFTWARE ENGINEER",
      className: "uppercase",
      color:  modelFactory.getModel({type: "color", options:{
        start: "quaternary-500"
      }}),
    }}),
    subheading: modelFactory.getModel({type: "text", options:{
      text: `I have been working as Software Engineer for more than 15 years`,
      color: modelFactory.getModel({type: "color", options:{
        start: "neutral-500"
      }}),
    }}),
    sections: [
      modelFactory.clone(featureItem, {
        media: {
          image: modelFactory.getModel({type: "image", options: {
            src: "https://images.unsplash.com/photo-1534972195531-d756b9bfa9f2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2600&q=80"
          }})
        },
        heading: modelFactory.getModel({type: "text", options: {
          text: "Real-world experience",
          color:  modelFactory.getModel({type: "color", options:{
            gradient: true,
            start: "primary-500",
            end: "secondary-500"
          }}),
        }}),
        subheading: modelFactory.getModel({type: "text", options: {
          text: `I have been working in several industries and products`,
          color: modelFactory.getModel({type: "color", options: {
            start: "neutral-500"
          }})
        }}),
        itemList: {
          items: [
            modelFactory.getModel({type: "feature_item_item", options: {
              id: 1,
              name: modelFactory.getModel({type: "text", options:{
                text: `Industries`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "quaternary-500"
                }})
              }}),
              description: modelFactory.getModel({type: "text", options:{
                text: `I have been working in healthcare, fintech, news agencies and edtech`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "neutral-500"
                }})
              }}),
              icon: modelFactory.getModel({type: "icon", options:{
                name: "hero_checkin",
                size: {
                  height: 10
                },
                className: "ml-2 mt-5",
                color: modelFactory.getModel({type: "color", options: {
                  start: "white"
                }})
              }}),
            }}),
            modelFactory.getModel({type: "feature_item_item", options: {
              id: 2,
              name: modelFactory.getModel({type: "text", options:{
                text: `International Experience`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "quaternary-500"
                }})
              }}),
              description: modelFactory.getModel({type: "text", options:{
                text: `I have more than 15 years of international experience.`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "neutral-500"
                }})
              }}),
              icon: modelFactory.getModel({type: "icon", options:{
                name: "hero_checkin",
                size: {
                  height: 10
                },
                className: "ml-2 mt-5",
                color: modelFactory.getModel({type: "color", options: {
                  start: "white"
                }})
              }}),
            }}),
            modelFactory.getModel({type: "feature_item_item", options: {
              id: 3,
              name: modelFactory.getModel({type: "text", options:{
                text: `SMB and Startups`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "quaternary-500"
                }})
              }}),
              description: modelFactory.getModel({type: "text", options:{
                text: `Experienced software engineer working for both SMB and startups as well as working 
                for one of the biggest news agency`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "neutral-500"
                }})
              }}),
              icon: modelFactory.getModel({type: "icon", options:{
                name: "hero_checkin",
                size: {
                  height: 10
                },
                className: "ml-2 mt-5",
                color: modelFactory.getModel({type: "color", options: {
                  start: "white"
                }})
              }}),
            }})
          ]
        }
      }),
      modelFactory.clone(featureItem, {
        heading: modelFactory.getModel({type: "text", options: {
          text: "Professional",
          color:  modelFactory.getModel({type: "color", options:{
            gradient: true,
            end: "primary-500",
            start: "secondary-500"
          }}),
        }}),
        subheading: modelFactory.getModel({type: "text", options: {
          text: `I have experience working in different part of the SDLC`,
          color: modelFactory.getModel({type: "color", options: {
            start: "neutral-500"
          }})
        }}),
        media: {
          image: modelFactory.getModel({type: "image", options: {
            src: "https://tailwindui.com/img/features/feature-example-1.png"
          }})
        },
        itemList: {
          items: [
            modelFactory.getModel({type: "feature_item_item", options: {
              id: 1,
              name: modelFactory.getModel({type: "text", options:{
                text: `Fullstack`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "quaternary-500"
                }})
              }}),
              description: modelFactory.getModel({type: "text", options:{
                text: `Worked as fullstack for several years working with technologies 
                like Javascript, CSS, ReactJS, NodeJS, Python, C# as well as Java`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "neutral-500"
                }})
              }}),
              icon: modelFactory.getModel({type: "icon", options:{
                name: "hero_checkin",
                size: {
                  height: 10
                },
                className: "ml-2 mt-5",
                color: modelFactory.getModel({type: "color", options: {
                  start: "white"
                }})
              }}),
            }}),
            modelFactory.getModel({type: "feature_item_item", options: {
              id: 2,
              name: modelFactory.getModel({type: "text", options:{
                text: `Mobile Developer`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "quaternary-500"
                }})
              }}),
              description: modelFactory.getModel({type: "text", options:{
                text: `I have been building mobile apps using both Swift as well as React Native`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "neutral-500"
                }})
              }}),
              icon: modelFactory.getModel({type: "icon", options:{
                name: "hero_checkin",
                size: {
                  height: 10
                },
                className: "ml-2 mt-5",
                color: modelFactory.getModel({type: "color", options: {
                  start: "white"
                }})
              }}),
            }}),
            modelFactory.getModel({type: "feature_item_item", options: {
              id: 3,
              name: modelFactory.getModel({type: "text", options:{
                text: `DevOps & Infrastructure`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "quaternary-500"
                }})
              }}),
              description: modelFactory.getModel({type: "text", options:{
                text: `Experience working with DevOps tasks.`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "neutral-500"
                }})
              }}),
              icon: modelFactory.getModel({type: "icon", options:{
                name: "hero_checkin",
                size: {
                  height: 10
                },
                className: "ml-2 mt-5",
                color: modelFactory.getModel({type: "color", options: {
                  start: "white"
                }})
              }}),
            }})
          ]
        }
      })
    ]
  }}),
  featuresTestimonial: modelFactory.getModel({type: "feature", options:{
    background: {
      color: modelFactory.getModel({type: "text", options: {
        start: "white"
      }})
    },
    heading: modelFactory.getModel({type: "text", options:{
      text: "REAL-WORLD PROJECTS",
      color:  modelFactory.getModel({type: "color", options:{
        start: "quaternary-500"
      }}),
    }}),
    subheading: modelFactory.getModel({type: "text", options:{
      text: `Some of the projects that I have been working on in the last 15 years`,
      color: modelFactory.getModel({type: "color", options:{
        start: "neutral-500"
      }}),
    }}),
    sections: [
      modelFactory.clone(featureItem, {
        media: {
          image: modelFactory.getModel({type: "image", options: {
            src: "https://raw.githubusercontent.com/domenicosolazzo/images/main/images/projects/pms.png"
          }})
        },
        heading: modelFactory.getModel({type: "text", options: {
          text: "Patient Monitoring System (PMS)",
          color:  modelFactory.getModel({type: "color", options:{
            gradient: true,
            start: "primary-500",
            end: "secondary-500"
          }}),
        }}),
        icon: modelFactory.getModel({type: "icon", options:{
          name: "hero_checkin",
          size: {
            height: 10
          },
          className: "ml-2 mt-5",
          color: modelFactory.getModel({type: "color", options: {
            start: "white"
          }})
        }}),
        subheading: modelFactory.getModel({type: "text", options: {
          text: `Patient Monitoring System (PMS). The system was continuos monitoring of physiological signals of patients.. Data is from multiple sources: web, mobile and IoT devices.
          Dashboard is built using ReactJS, Javascript and some of the technologies in the backend are NodeJS, Apache Kafka and GraphQL`,
          color: modelFactory.getModel({type: "color", options: {
            start: "neutral-500"
          }})
        }}),
        testimonial: null,
        actions: null,
        itemList: null
      }),
      modelFactory.clone(featureItem, {
        heading: modelFactory.getModel({type: "text", options: {
          text: "JROC",
          color:  modelFactory.getModel({type: "color", options:{
            gradient: true,
            end: "primary-500",
            start: "secondary-500"
          }}),
        }}),
        icon: modelFactory.getModel({type: "icon", options:{
          name: "hero_checkin",
          size: {
            height: 10
          },
          className: "ml-2 mt-5",
          color: modelFactory.getModel({type: "color", options: {
            start: "white"
          }})
        }}),
        subheading: modelFactory.getModel({type: "text", options: {
          text: `A REST API for tagging and entity extraction of documents. It can extract entity information in several languages like Norwegian, English and Spanish.
          This api could find events, people and organization throughout news articles in different languages.
          The system was built using Flask, Python and Natural Language Processing (NLP).`,
          color: modelFactory.getModel({type: "color", options: {
            start: "neutral-500"
          }})
        }}),
        media: {
          image: modelFactory.getModel({type: "image", options: {
            src: "https://raw.githubusercontent.com/domenicosolazzo/images/main/images/projects/jroc.png",
          }})
        },
        testimonial: null,
        actions: null,
        itemList: null,
        metadata: {
          reverse: true
        }
      }),
      modelFactory.clone(featureItem, {
        heading: modelFactory.getModel({type: "text", options: {
          text: "Fitness Inner Circle App",
          color:  modelFactory.getModel({type: "color", options:{
            gradient: true,
            start: "primary-500",
            end: "secondary-500"
          }}),
        }}),
        icon: modelFactory.getModel({type: "icon", options:{
          name: "hero_checkin",
          size: {
            height: 10
          },
          className: "ml-2 mt-5",
          color: modelFactory.getModel({type: "color", options: {
            start: "white"
          }})
        }}),
        subheading: modelFactory.getModel({type: "text", options: {
          text: `The app for the Inner Circle for famous fitness influencers.
          Using this app you could find all the videos and explanation of the different workouts,
          a workout tracker and consistency calendar to keep you fit and motivated.
          The app was built using React Native for both Android and IOS.`,
          color: modelFactory.getModel({type: "color", options: {
            start: "neutral-500"
          }})
        }}),
        media: {
          image: modelFactory.getModel({type: "image", options: {
            src: "https://raw.githubusercontent.com/domenicosolazzo/images/main/images/projects/innercircle.png"
          }})
        },
        testimonial: null,
        actions: null,
        itemList: null,
        metadata: {
          reverse: false
        }
      }),
    ]
  }}),
  testimonial: modelFactory.getModel({ type: "testimonial_overalapping_image", options: {
    message: modelFactory.getModel({type: "text", options: {
      text: `Domenico is working as a senior front end engineer developing our mobile payments app(in React Native). 
      He has contributed greatly to increasing the quality of our code, he is a hard worker, he is always helpful and is an excellent source of knowledge, a knowledge that he is good at sharing with his colleagues. 
      He has also helped me, as his manager, to become better by constructively proposing ways of how we can improve our organization, leadership, and processes.
      `,
      color: modelFactory.getModel({type: "color", options: {
        start: "white"
      }})
    }}),
    background:{
      color: modelFactory.getModel({type: "color", options: {
        start: "primary-500",
        metadata: {
          property: "bg"
        }
      }})
    },
    image:modelFactory.getModel({type: "image", options: {
      src: "https://images.unsplash.com/photo-1546146830-2cca9512c68e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2102&q=80"
    }}),
    author: {
      name: modelFactory.getModel({type: "text", options: {
        text: "Erik E.",
        color: modelFactory.getModel({type: "color", options: {
          start: "quaternary-500"
        }})
      }}),
      jobTitle: modelFactory.getModel({type: "text", options: {
        text: "CTO",
        color: modelFactory.getModel({type: "color", options: {
          start: "quaternary-500"
        }})
      }}),
    }
  }}),
  contact: ContactData.getContactInfo({
    items: [
      {title: "Contact", email: `${DataLinks.emails.contact}`, message: "Do you want to work with me, hire me or do you just want to have a chat? Contact me to the email below"},
      {title: "Collaborate", email: `${DataLinks.emails.business}`, message: "Do you want to have a collaboration with me on Youtube, Facebook, Instagram or TikTok?"}
    ]
  }) 
}

export default PageData