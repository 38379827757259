import { Link as GatsbyLink } from "gatsby"
import { modelFactory } from "@domenicosolazzo/site-designsystem"

const generateLink = (data) => {
  const {
    url, 
    linkText,
    target,
    isExternal
  } = data
  return modelFactory.getModel({type: "link", options: {
    hrefAttribute: isExternal === true ? "href" : "to",
    url: url,
    target: target, 
    linkText: linkText,
    component: isExternal === true? null : GatsbyLink
  }})
}
const emails = {
  business: 'business@domenicosolazzo.com',
  contact: 'contact@domenicosolazzo.com',
  privacy: 'privacy@domenicosolazzo.com'
}

const blog_posts = {
  'help_junior_developer': generateLink({url: "/blog/help-junior-developers/", linkText: "Help Junior Developers"}),
}

const videos = {
  youtube: {
    'automate_videos_with_python': generateLink({url: 'https://www.youtube.com/watch?v=aQKrjYC3gfg&t=4s', linkText: 'Automate Videos with Python', isExternal: true})
  }
}

const instagram_posts = {
  'tools_i_use_everyday': generateLink({url: 'https://www.instagram.com/p/CPS_4EyI49Z/', linkText: 'Tools I use everyday', isExternal: true})
}

const links = {
  'home': generateLink({ url: "/", linkText: "Home" }),
  'contact': generateLink({ url: "/contact", linkText: "Contact" }),
  'portfolio': generateLink({ url: "/portfolio", linkText: "Portfolio" }),
  'blog': generateLink({ url: "/blog", linkText: "Blog" }),
  'developer': generateLink({ url: "/developer", linkText: "Software Engineer" }),
  'leadership': generateLink({ url: "/leadership", linkText: "Leadership" }),
  'creator': generateLink({ url: "/creator", linkText: "Content Creator" }),
  'community_builder': generateLink({ url: "/community_builder", linkText: "Community Builder" }),
  'cv': generateLink({ url: "/cv", linkText: "CV" }),
  'youtube': generateLink({ url: "https://www.youtube.com/c/domenicosolazzo", linkText: "Youtube", target: "_blank", isExternal: true }),
  'instagram': generateLink({ url: "https://www.instagram.com/domenicosolazzo/", linkText: "Instagram", target: "_blank", isExternal: true }),
  'tiktok': generateLink({ url: "https://www.tiktok.com/domenicosolazzo", linkText: "TikTok", target: "_blank", isExternal: true }),
  'linkedin': generateLink({ url: "https://www.linkedin.com/in/solazzo", linkText: "Linkedin", target: "_blank", isExternal: true }),
  'facebook': generateLink({ url: "https://www.facebook.com/domenicosolazzolabs", linkText: "Facebook", target: "_blank", isExternal: true }),
  'github': generateLink({ url: "https://www.github.com/domenicosolazzo", linkText: "Github", target: "_blank", isExternal: true }),
  'hashnode': generateLink({ url: "https://hashnode.com/@domenicosolazzo", linkText: "HashNode", target: "_blank", isExternal: true }),
  'medium': generateLink({ url: "https://domenicosolazzo.medium.com/", linkText: "Medium", target: "_blank", isExternal: true }),
  'devto': generateLink({ url: "https://dev.to/domenicosolazzo", linkText: "DevTo", target: "_blank", isExternal: true }),
  'twitter': generateLink({ url: "https://www.twitter.com/domenicosolazzo", linkText: "Twitter", target: "_blank", isExternal: true }),
  'privacy': generateLink({ url: "/privacy_policy", linkText: "Privacy Policy" }),
  'videos': videos,
  'instagram_posts': instagram_posts,
  blog_posts,
  emails
}

export default links;