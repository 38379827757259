import React, {useEffect} from "react"
import Helmet from 'react-helmet';
// import { useStaticQuery, graphql } from "gatsby"
// import { Link as GatsbyLink } from "gatsby"
import { 
    Navbar,
    Footer
} from '@domenicosolazzo/site-designsystem';
import ErrorBoundary from "../../../errors/ErrorHandler"
import { 
    DataNavbar,
    DataLinks,
    DataFooter,
    Error404
} from "../../../data"
import SEO from "../../SEO/SEO";


export default function Layout({children}) { 
    useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "https://cdn.iubenda.com/iubenda.js";
        script.async = true;
      
        document.body.appendChild(script);
      
        return () => {
          document.body.removeChild(script);
        }
      }, []);  
    return (
        <>
            <Helmet key="app-head">
                <html lang="en" />
                <meta charSet="utf-8" />
                <meta name="viewport" content="initial-scale=1.0, width=device-width" />

                {/* Favicons from realfavicongenerator.net */}
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                <link rel="manifest" href="/manifest.json" />
                <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#39bbdf" />
                <meta name="msapplication-TileColor" content="#39bbdf" />
                <meta name="theme-color" content="#39bbdf" />
            </Helmet>
            <ErrorBoundary>
                <Navbar {...DataNavbar}/>
                <>
                    {children}
                </>
                <Footer  {...DataFooter}/>
            </ErrorBoundary>
           
        </>
    )
}