import React from "react"
import { modelFactory } from "@domenicosolazzo/site-designsystem"


const contactItem = (data) => {
  const {
    title="Contact",
    email="contact@domenicosolazzo.com",
    contact="Contact Me",
    message="Do you want to work with me, hire me or do you just want to have a chat? Contact me to the email below", // Do you want to have a collaboration with me on Youtube, Facebook, Instagram or TikTok?
  } = data

  return {
    heading: modelFactory.getModel({type: "text", options: {
      text: `${title}`,
      color: modelFactory.getModel({type: "color", options: {
        start: "quaternary-500"
      }}),
      wrapper:{
        type:"div",
        className: "",
        hidden: false,
      },
      metadata: {
        type: "p"
      }
    }}),
    description: modelFactory.getModel({type: "text", options: {
      text: `${message}`,
      color: modelFactory.getModel({type: "color", options: {
        start: "neutral-500"
      }})
    }}),
    contact:  modelFactory.getModel({type:"text", options: {
      text: `${contact}`,
      className: "flex-1",
      color: modelFactory.getModel({type: "color", options: {
        start: "quaternary-500"
      }})
    }}),
    email: modelFactory.getModel({type: "link", options: {
      linkText: `${email}`
    }})
  }
}
const getContactInfo = (data) => {
  const {
    items
  } = data

  const contactItems = items.map((item) => contactItem(item))
  return {
    items: contactItems
  }
}

const data = {
  getContactInfo,
  contact: {
    items: [
      {
        heading: modelFactory.getModel({type: "text", options: {
          text: "Contact",
          color: modelFactory.getModel({type: "color", options: {
            start: "neutral-900"
          }}),
          wrapper:{
            type:"div",
            className: "",
            hidden: false,
          },
          metadata: {
            type: "p"
          }
        }}),
        description: modelFactory.getModel({type: "text", options: {
          text: "Do you want to work with me, hire me or do you just want to have a chat? Contact me to the email below",
          color: modelFactory.getModel({type: "color", options: {
            start: "neutral-500"
          }})
        }}),
        contact:  modelFactory.getModel({type:"text", options: {
          text: "Contact me",
          className: "flex-1",
          color: modelFactory.getModel({type: "color", options: {
            start: "neutral-900"
          }})
        }}),
        email: modelFactory.getModel({type: "link", options: {
          linkText: "business@domenicosolazzo.com"
        }})
      },
      {
        heading: modelFactory.getModel({type: "text", options: {
          text: "Collaborate",
          color: modelFactory.getModel({type: "color", options: {
            start: "neutral-900"
          }})
        }}),
        description: modelFactory.getModel({type: "text", options: {
          text: "Do you want to have a collaboration with me on Youtube, Facebook, Instagram or TikTok?",
          color: modelFactory.getModel({type: "color", options: {
            start: "neutral-500"
          }})
        }}),
        contact:  modelFactory.getModel({type:"text", options: {
          text: "Contact Me",
          color: modelFactory.getModel({type: "color", options: {
            start: "neutral-400"
          }})
        }}),
        email: modelFactory.getModel({type: "link", options: {
          linkText: "business@domenicosolazzo.com"
        }})
      }
    ]
  }
}
const PageData = {
  ...data
}

export default PageData