import { modelFactory } from "@domenicosolazzo/site-designsystem"
import {
  BookmarkAltIcon,
  CalendarIcon,
  ShieldCheckIcon,
  SupportIcon,
} from '@heroicons/react/outline'
import { DataLinks } from "../index"

const iconColor = "#f47b3e"

const navbarData = modelFactory.getModel({type: "navbar_centered", options: {
  logo:{
    image:  modelFactory.getModel({
      type: "icon", 
      options:{
        name: "terminal",
        icon: {
          name: "fa-terminal"
        },
        size:{
          width:60,
          height: 60,
        },
        alt: "",
        wrapper: {
            hidden: true
        },
      }
    }),
    link: modelFactory.clone(DataLinks.home, {})
  },
  general:{
    author: "Domenico Solazzo",
    menu: {
      open: {
        text: "Open Menu"
      },
      close: {
        text: "Close Menu"
      }
    }
  },
  navLinks: {
    links: [
      {
        order: 0, 
        type: "home", 
        name: "Home", 
        children: [], 
        link: modelFactory.clone(DataLinks.home, {}), 
        className: "text-base font-medium text-gray-500 hover:text-gray-900"
      },
      {
        order: 5, 
        type: "contact", 
        name: "Contact", 
        children: [], 
        link: modelFactory.clone(DataLinks.contact, {}), 
        className: "text-base font-medium text-gray-500 hover:text-gray-900"},
      {
        order: 1, 
        type: "links", 
        name: "Experience", 
        children: [
          {
            order: 0,
            type: "link",
            name: 'Software Engineer',
            description: 'The career as a software engineer.',
            icon: modelFactory.getModel({type:"icon", options:{icon: {name: "fa-code", size: "lg", color: iconColor}}}),
            link:modelFactory.clone(DataLinks.developer, {}), 
          },
          {
            order: 1,
            type: "link",
            name: 'Management',
            description: 'Learn about the experience in management and leadership',
            icon: modelFactory.getModel({type:"icon", options:{icon: {name: "fa-user-tie", size: "lg", color: iconColor}}}),
            link: modelFactory.clone(DataLinks.leadership, {}), 
          },
          {
            order: 2,
            type: "link",
            name: 'Content Creator',
            description: 'See my experience and content as content creator',
            icon: modelFactory.getModel({type:"icon", options:{icon: {name: "fa-video", size: "lg", color: iconColor}}}),
            link: modelFactory.clone(DataLinks.creator, {}), 
          },
          { 
            order: 3,
            type: "link",
            name: 'Community Builder', 
            description: 'I love building online and offline communities', 
            icon: modelFactory.getModel({type:"icon", options:{icon: {name: "fa-meetup", size: "lg", color: iconColor, brand:true}}}),
            link: modelFactory.clone(DataLinks.community_builder, {}), 
          },
        ], 
        link: null, 
        className: "text-base font-medium text-gray-500 hover:text-gray-900"
      },
      { 
        order: 2, 
        type: "links", 
        name: "Social", 
        link: null, 
        className: "text-base font-medium text-gray-500 hover:text-gray-900",
        children: [
          {
            order: 0,
            name: 'Blog',
            description: 'My blog',
            icon: modelFactory.getModel({type:"icon", options:{icon: {name: "fa-medium", size: "lg", color: iconColor, brand:true}}}),
            link: modelFactory.clone(DataLinks.blog, {}), 
          },
          {
            order: 1,
            name: 'Youtube',
            description: 'Youtube Channel',
            icon: modelFactory.getModel({type:"icon", options:{icon: {name: "fa-youtube", size: "lg", color: iconColor, brand:true}}}),
            link: modelFactory.clone(DataLinks.youtube, {}), 
          },
          {
            order: 2,
            name: 'Instagram',
            description: 'My content on Instagram',
            icon: modelFactory.getModel({type:"icon", options:{icon: {name: "fa-instagram", size: "lg", color: iconColor, brand:true}}}),
            link: modelFactory.clone(DataLinks.instagram, {}), 
          },
          { 
            order: 3,
            name: 'TikTok', 
            description: 'Just for fun', 
            icon: modelFactory.getModel({type:"icon", options:{icon: {name: "fa-tiktok", size: "lg", color: iconColor, brand:true}}}),
            link: modelFactory.clone(DataLinks.tiktok, {}), 
          },
          { 
            order: 4,
            name: 'DevTo', 
            description: 'A developer writing for developers', 
            icon: modelFactory.getModel({type:"icon", options:{icon: {name: "fa-dev", size: "lg", color: iconColor, brand:true}}}),
            link: modelFactory.clone(DataLinks.devto, {}), 
          },
          {   
            order: 5,
            name: 'Linkedin', 
            description: 'Connect with me on Linkedin', 
            icon: modelFactory.getModel({type:"icon", options:{icon: {name: "fa-linkedin", size: "lg", color: iconColor, brand:true}}}),
            link: modelFactory.clone(DataLinks.linkedin, {}), 
          },
          { 
            order: 6,
            name: 'Github', 
            description: 'Writing code to become a better developer', 
            icon: modelFactory.getModel({type:"icon", options:{icon: {name: "fa-github", size: "lg", color: iconColor, brand:true}}}),
            link: modelFactory.clone(DataLinks.github, {}), 
          },
          { 
            order: 7,
            name: 'Twitter', 
            description: 'Tweeting about everything!! :)', 
            icon: modelFactory.getModel({type:"icon", options:{icon: {name: "fa-twitter", size: "lg", color: iconColor, brand:true}}}),
            link: modelFactory.clone(DataLinks.twitter, {}), 
          },
        ]
      }
    ]
  } 
  
}})


export default navbarData