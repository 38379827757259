import React from "react"
import { modelFactory } from "@domenicosolazzo/site-designsystem"
import { DataLinks } from "../../.."
import { ContactData } from "../../components"

const featureItem = modelFactory.getModel({type: "feature_item", options:{}})

const PageData = {
  hero:modelFactory.getModel({type: "hero_with_media", options: {
    slogan: modelFactory.getModel({type: "text", options: {
      text: "Software engineer"
    }}),
    heading: modelFactory.getModel({type: "text", options: {
      text: (<p>
        <span className="text-primary-500">I</span> 💖 <span className="text-transparent bg-clip-text text-gradient-to-r from-primary-500  to-secondary-500 text-gradient-to-r from-primary-500  to-secondary-500 text-opacity-100 bg-gradient-to-r from-transparent  to-transparent bg-opacity-100">BUILDING COMMUNITIES!
        </span>
      </p>),
    }}),
    background: {
      color: modelFactory.getModel({type:"color", options: {start: "white", metadata: {property: "bg"}}})
    },
    subheading: modelFactory.getModel({type: "text", options: {
      text: `Strong communities have a significant sense of purpose. `,
      color: modelFactory.getModel({type: "color", options: {
        start: "neutral-500"
      }}),
    }}),
    actions: null,
    media: {
      image: modelFactory.getModel({type: "image", options: {
        src: "https://images.unsplash.com/photo-1576085898274-069be5a26c58?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1534&q=80"  
      }})
    }
  }}),
  logoCloud:modelFactory.getModel({type:"logo_cloud", options: {
  
      heading: modelFactory.getModel({type: "text", options:{
        text: "SOME OF THE EVENTS I HAVE BEEN ORGANIZING",
        color:  modelFactory.getModel({type: "color", options:{
          start: "white"
        }}),
        className: "text-xl lg:text-2xl",
        wrapper:{
          type:"div",
          className: "",
          hidden: false,
        },
        metadata: {
          type: "p"
        }
      }}),
      background: modelFactory.getModel({type: "color", options: { 
        gradient: true,
        start: "primary-500",
        end: "secondary-500",
        metadata: {
          property: "bg"
        }
      }}),
      logos: [
        {
          image: modelFactory.getModel({type: "image", options: {src: "https://www.meetup.com/mu_static/en-US/logo--mSwarm--2color.4ff7188b.svg", className: "w-24", size: {height: 20, width: 20}}}),
          link: null
        }, 
        {
          image: modelFactory.getModel({type: "image", options: {src: "https://www.flywheelcoworking.com/wp-content/uploads/2016/03/startup-weekend-logo.png", size: {height: 20, width: 20}}}),
          link: null
        },
        {
          image: modelFactory.getModel({type: "image", options: {src: "https://nordicstartupawards.com/wp-content/uploads/2021/01/NSA_logo_normal_1-1.svg", size: {height: 20, width: 20}}}),
          link: null
        }, 
      ]
    }
  }),
  features: modelFactory.getModel({type: "feature", options:{
    background: {
      color: modelFactory.getModel({type: "text", options: {
        start: "white"
      }})
    },
    heading: modelFactory.getModel({type: "text", options:{
      text: "COMMUNITY BUILDER",
      color:  modelFactory.getModel({type: "color", options:{
        start: "quaternary-500"
      }}),
    }}),
    subheading: modelFactory.getModel({type: "text", options:{
      text: `I have been building communities around software engineering and entrepreneurship in the last 10 years. From Startup Weekend to some of the biggest 
      developer communities in the Nordics.`,
      color: modelFactory.getModel({type: "color", options:{
        start: "neutral-500"
      }}),
    }}),
    sections: [
      modelFactory.clone(featureItem, {
        media: {
          image: modelFactory.getModel({type: "image", options: {
            src: "https://images.unsplash.com/photo-1576085898323-218337e3e43c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1600&q=80"
          }})
        },
        heading: modelFactory.getModel({type: "text", options: {
          text: "COMMUNITY",
          color:  modelFactory.getModel({type: "color", options:{
            gradient: true,
            start: "primary-500",
            end: "secondary-500"
          }}),
        }}),
        subheading: modelFactory.getModel({type: "text", options: {
          text: `Strong communities have a significant sense of purpose. 
          Few things I look for when building them.`,
          color: modelFactory.getModel({type: "color", options: {
            start: "neutral-500"
          }})
        }}),
        itemList: {
          items: [
            modelFactory.getModel({type: "feature_item_item", options: {
              id: 1,
              name: modelFactory.getModel({type: "text", options:{
                text: `Purpose`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "quaternary-500"
                }})
              }}),
              description: modelFactory.getModel({type: "text", options:{
                text: `I always try to create a sense of purpose in every community I am building `,
                color: modelFactory.getModel({type: "color", options: {
                  start: "neutral-500",
                }})
              }}),
              icon: modelFactory.getModel({type: "icon", options:{
                name: "hero_checkin",
                size: {
                  height: 10
                },
                className: "ml-2 mt-5",
                color: modelFactory.getModel({type: "color", options: {
                  start: "white"
                }})
              }}),
            }}),
            modelFactory.getModel({type: "feature_item_item", options: {
              id: 2,
              name: modelFactory.getModel({type: "text", options:{
                text: `Belonging`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "quaternary-500"
                }})
              }}),
              description: modelFactory.getModel({type: "text", options:{
                text: `As Plato said, “The part cannot be well unless the whole is well.” 
                Great communities have a sense of kinship, camaraderie and connectedness. `,
                color: modelFactory.getModel({type: "color", options: {
                  start: "neutral-500"
                }})
              }}),
              icon: modelFactory.getModel({type: "icon", options:{
                name: "hero_checkin",
                size: {
                  height: 10
                },
                className: "ml-2 mt-5",
                color: modelFactory.getModel({type: "color", options: {
                  start: "white"
                }})
              }}),
            }}),
            modelFactory.getModel({type: "feature_item_item", options: {
              id: 3,
              name: modelFactory.getModel({type: "text", options:{
                text: `Keep Learning`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "quaternary-500"
                }})
              }}),
              description: modelFactory.getModel({type: "text", options:{
                text: `I strive to help people to keep learning with all these events`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "neutral-500"
                }})
              }}),
              icon: modelFactory.getModel({type: "icon", options:{
                name: "hero_checkin",
                size: {
                  height: 10
                },
                className: "ml-2 mt-5",
                color: modelFactory.getModel({type: "color", options: {
                  start: "white"
                }})
              }}),
            }})
          ]
        }
      }),
      modelFactory.clone(featureItem, {
        heading: modelFactory.getModel({type: "text", options: {
          text: "EVENTS",
          color:  modelFactory.getModel({type: "color", options:{
            gradient: true,
            end: "primary-500",
            start: "secondary-500"
          }}),
        }}),
        subheading: modelFactory.getModel({type: "text", options: {
          text: `I have organize several events in the past 10 years`,
          color: modelFactory.getModel({type: "color", options: {
            start: "neutral-500"
          }})
        }}),
        media: {
          image: modelFactory.getModel({type: "image", options: {
            src: "https://tailwindui.com/img/features/feature-example-1.png"
          }})
        },
        itemList: {
          items: [
            modelFactory.getModel({type: "feature_item_item", options: {
              id: 1,
              name: modelFactory.getModel({type: "text", options:{
                text: `Startup Weekend`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "quaternary-500"
                }})
              }}),
              description: modelFactory.getModel({type: "text", options:{
                text: `54 hr Hackathon event from Friday to Sunday. Brainstorm, evaluate and build ideas in a short amount of time.`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "neutral-500"
                }})
              }}),
              icon: modelFactory.getModel({type: "icon", options:{
                name: "hero_checkin",
                size: {
                  height: 10
                },
                className: "ml-2 mt-5",
                color: modelFactory.getModel({type: "color", options: {
                  start: "white"
                }})
              }}),
            }}),
            modelFactory.getModel({type: "feature_item_item", options: {
              id: 2,
              name: modelFactory.getModel({type: "text", options:{
                text: `ReactJS Norway`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "quaternary-500"
                }})
              }}),
              description: modelFactory.getModel({type: "text", options:{
                text: `Frontend community focused on ReactJS, Javascript and CSS.`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "neutral-500"
                }})
              }}),
              icon: modelFactory.getModel({type: "icon", options:{
                name: "hero_checkin",
                size: {
                  height: 10
                },
                className: "ml-2 mt-5",
                color: modelFactory.getModel({type: "color", options: {
                  start: "white"
                }})
              }}),
            }}),
            modelFactory.getModel({type: "feature_item_item", options: {
              id: 3,
              name: modelFactory.getModel({type: "text", options:{
                text: `Leadership in Tech`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "quaternary-500"
                }})
              }}),
              description: modelFactory.getModel({type: "text", options:{
                text: `Interviewing and talking to leaders in tech about challenges in their daily work`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "neutral-500"
                }})
              }}),
              icon: modelFactory.getModel({type: "icon", options:{
                name: "hero_checkin",
                size: {
                  height: 10
                },
                className: "ml-2 mt-5",
                color: modelFactory.getModel({type: "color", options: {
                  start: "white"
                }})
              }}),
            }})
          ]
        }
      })
    ]
  }}),
  contact: ContactData.getContactInfo({
    items: [
      {title: "Contact", email: `${DataLinks.emails.contact}`, message: "Do you want to work with me, hire me or do you just want to have a chat? Contact me to the email below"},
      {title: "Collaborate", email: `${DataLinks.emails.business}`, message: "Do you want to have a collaboration with me on Youtube, Facebook, Instagram or TikTok?"}
    ]
  }) 
}

export default PageData