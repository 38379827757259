import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from "gatsby"
import Twitter from './Twitter';
import Facebook from './Facebook';

const SEO = ({
  title = null,
  description = null,
  image = null,
  pathname = null,
  article = false,
}) => {

  const data = useStaticQuery(graphql`
    query {
        site {
          siteMetadata {
            defaultTitle: title
            titleTemplate
            defaultDescription: description
            siteUrl
            defaultImage: image
            twitterUsername
            facebookAppID
          }
        }
      }
  `)

  const siteUrl = data.site.siteMetadata.siteUrl
  const url = pathname?.includes('http') ? pathname : `${siteUrl}${pathname || '/'}`
  const seoImage = image?.includes('http') ? image :  `${siteUrl}${image || data.site.siteMetadata.defaultImage}`
  const seo ={
    title: title || data.site.siteMetadata.defaultTitle,
    description: description || data.site.siteMetadata.defaultDescription,
    image: seoImage,
    url: url,
    titleTemplate: data.site.siteMetadata.titleTemplate,
    facebookAppID:data.site.siteMetadata.facebookAppID,
    twitterUsername:data.site.siteMetadata.twitterUsername,
    article
  }

  return (
    <>
      <Helmet title={seo.title} titleTemplate={seo.titleTemplate}>
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
      </Helmet>
      <Facebook
        pageUrl={seo.url}
        type={seo.article ? 'article' : null}
        title={seo.title}
        description={seo.description}
        image={seo.image}
        appID={seo.facebookAppID}
      />
      <Twitter
        username={seo.twitterUsername}
        title={seo.title}
        description={seo.description}
        image={seo.image}
      />
    </>
  );
 
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
};

export default SEO;