import React from "react"
import { modelFactory } from "@domenicosolazzo/site-designsystem"
import { DataLinks } from "../../.."
import { ContactData } from "../../components"

const featureItem = modelFactory.getModel({type: "feature_item", options:{}})

const PageData = {
  hero:modelFactory.getModel({type: "hero_with_media", options: {
    slogan: modelFactory.getModel({type: "text", options: {
      text: "Software engineer"
    }}),
    heading: modelFactory.getModel({type: "text", options: {
      text: (<p>
        <span className="text-primary-500">I</span> 💖 <span className="text-transparent bg-clip-text text-gradient-to-r from-primary-500  to-secondary-500 text-gradient-to-r from-primary-500  to-secondary-500 text-opacity-100 bg-gradient-to-r from-transparent  to-transparent bg-opacity-100">CREATING CONTENT!
        </span>
      </p>),
      wrapper:{
        type:"div",
        className: "",
        hidden: false,
      },
      metadata: {
        type: "p"
      }
    }}),
    background: {
      color: modelFactory.getModel({type:"color", options: {start: "white", metadata: {property: "bg"}}})
    },
    subheading: modelFactory.getModel({type: "text", options: {
      text: `Sharing is caring. I have been lucky to get my education and working experience and that's why I love to share 
      all that I know with people on the Internet. 
      Hoping to make an impact on each one of you and helping you to reach your goals.`,
      color: modelFactory.getModel({type: "color", options: {
        start: "neutral-500"
      }}),
    }}),
    actions: null,
    media: {
      image: modelFactory.getModel({type: "image", options: {
        src: "https://miro.medium.com/max/1400/1*27GLsjJzMZLCLr7vsFoJPw.png"  
      }})
    }
  }}),
  logoCloud: modelFactory.getModel({type: "logo_cloud", options:{
    heading: modelFactory.getModel({type: "text", options:{
      text: "Where you can find me online",
      color:  modelFactory.getModel({type: "color", options:{
        start: "white"
      }}),
      className: "text-xl lg:text-4xl"
    }}),
    background: modelFactory.getModel({type: "color", options: { 
      gradient: true,
      start: "primary-500",
      end: "primary-500",
      metadata: {
        property: "bg"
      }
    }}),
    logos: [
      {
        image: modelFactory.getModel({type: "icon", options: {
          icon: {
            name: "fa-instagram",
            brand: true,
            color: "white",
            size: "4x",
            className: "mt-1 ml-4"
          }, size: {height: 60, width: 60}}}),
        link: DataLinks.instagram
      },
      {
        image: modelFactory.getModel({type: "icon", options: {
          icon: {
            name: "fa-facebook",
            brand: true,
            color: "white",
            size: "4x",
            className: "mt-1 ml-4"
          }, size: {height: 60, width: 60}}}),
        link: DataLinks.facebook
      },
      {
        image: modelFactory.getModel({type: "icon", options: {
          icon: {
            name: "fa-youtube",
            brand: true,
            color: "white",
            size: "4x",
            className: "mt-1 ml-4"
          }, size: {height: 60, width: 60}}}),
        link: DataLinks.youtube
      },
      {
        image: modelFactory.getModel({type: "icon", options: {
          icon: {
            name: "fa-medium",
            brand: true,
            color: "white",
            size: "4x",
            className: "mt-1 ml-4"
          },size: {height: 60, width: 60}}}),
        link: DataLinks.medium
      },
      {
        image: modelFactory.getModel({type: "icon", options: {
          icon: {
            name: "fa-dev",
            brand: true,
            color: "white",
            size: "4x",
            className: "mt-1 ml-4"
          },size: {height: 60, width: 60}}}),
        link: DataLinks.devto
      },
      {
        image: modelFactory.getModel({type: "icon", options: {
          icon: {
            name: "fa-github",
            brand: true,
            color: "white",
            size: "4x",
            className: "mt-1 ml-4"
          },size: {height: 60, width: 60}}}),
        link: DataLinks.github
      },
      {
        image: modelFactory.getModel({type: "icon", options: {
          icon: {
            name: "fa-linkedin",
            brand: true,
            color: "white",
            size: "4x",
            className: "mt-1 ml-4"
          },size: {height: 60, width: 60}}}),
        link: DataLinks.linkedin
      },
      {
        image: modelFactory.getModel({type: "icon", options: {
          icon: {
            name: "fa-twitter",
            brand: true,
            color: "white",
            size: "4x",
            className: "mt-1 ml-4"
          },size: {height: 60, width: 60}}}),
        link: DataLinks.twitter
      }
    ]
  }}),
  features: modelFactory.getModel({type: "feature", options:{
    background: {
      color: modelFactory.getModel({type: "text", options: {
        start: "white"
      }})
    },
    heading: modelFactory.getModel({type: "text", options:{
      text: "CONTENT CREATOR",
      color:  modelFactory.getModel({type: "color", options:{
        start: "quaternary-500"
      }}),
    }}),
    subheading: modelFactory.getModel({type: "text", options:{
      text: `Creating and sharing content is a passion of mine and really improve they way I learn and teach online.`,
      color: modelFactory.getModel({type: "color", options:{
        start: "neutral-500"
      }}),
    }}),
    sections: [
      modelFactory.clone(featureItem, {
        media: {
          image: modelFactory.getModel({type: "image", options: {
            src: "https://images.unsplash.com/photo-1510282271343-fdc3dea55439?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3092&q=80"
          }})
        },
        heading: modelFactory.getModel({type: "text", options: {
          text: "TOPICS",
          color:  modelFactory.getModel({type: "color", options:{
            gradient: true,
            start: "primary-500",
            end: "secondary-500"
          }}),
        }}),
        subheading: modelFactory.getModel({type: "text", options: {
          text: `I have been creating online content around software engineering, entrepreneurship and life as a developer in the past 5 years.`,
          color: modelFactory.getModel({type: "color", options: {
            start: "neutral-500"
          }})
        }}),
        itemList: {
          items: [
            modelFactory.getModel({type: "feature_item_item", options: {
              id: 1,
              name: modelFactory.getModel({type: "text", options:{
                text: `Software Engineering`,
                color:  modelFactory.getModel({type: "color", options:{
                  start: "quaternary-500",
                }}),
              }}),
              description: modelFactory.getModel({type: "text", options:{
                text: `Creating content around what I really love the most: Software Engineering`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "neutral-500"
                }})
              }}),
              icon: modelFactory.getModel({type: "icon", options:{
                name: "hero_checkin",
                size: {
                  height: 10
                },
                className: "ml-2 mt-5",
                color: modelFactory.getModel({type: "color", options: {
                  start: "white"
                }})
              }}),
            }}),
            modelFactory.getModel({type: "feature_item_item", options: {
              id: 2,
              name: modelFactory.getModel({type: "text", options:{
                text: `Life as a developer`,
                color:  modelFactory.getModel({type: "color", options:{
                  start: "quaternary-500",
                }}),
              }}),
              description: modelFactory.getModel({type: "text", options:{
                text: `Writing and explaing the life as developer from my own eyes. `,
                color: modelFactory.getModel({type: "color", options: {
                  start: "neutral-500"
                }})
              }}),
              icon: modelFactory.getModel({type: "icon", options:{
                name: "hero_checkin",
                size: {
                  height: 10
                },
                className: "ml-2 mt-5",
                color: modelFactory.getModel({type: "color", options: {
                  start: "white"
                }})
              }}),
            }}),
            modelFactory.getModel({type: "feature_item_item", options: {
              id: 3,
              name: modelFactory.getModel({type: "text", options:{
                text: `Entrepreneurship`,
                color:  modelFactory.getModel({type: "color", options:{
                  start: "quaternary-500",
                }}),
              }}),
              description: modelFactory.getModel({type: "text", options:{
                text: `Talking about entrepreneurship and the startup victories and challenges when opening your own business.`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "neutral-500"
                }})
              }}),
              icon: modelFactory.getModel({type: "icon", options:{
                name: "hero_checkin",
                size: {
                  height: 10
                },
                className: "ml-2 mt-5",
                color: modelFactory.getModel({type: "color", options: {
                  start: "white"
                }})
              }}),
            }})
          ]
        }
      }),
      modelFactory.clone(featureItem, {
        heading: modelFactory.getModel({type: "text", options: {
          text: "READ, WATCH, LISTEN",
          color:  modelFactory.getModel({type: "color", options:{
            gradient: true,
            end: "primary-500",
            start: "secondary-500"
          }}),
        }}),
        subheading: modelFactory.getModel({type: "text", options: {
          text: `I create content in different formats like blog posts, videos and audio content.`,
          color: modelFactory.getModel({type: "color", options: {
            start: "neutral-500"
          }})
        }}),
        media: {
          image: modelFactory.getModel({type: "image", options: {
            src: "https://tailwindui.com/img/features/feature-example-1.png"
          }})
        },
        itemList: {
          items: [
            modelFactory.getModel({type: "feature_item_item", options: {
              id: 1,
              name: modelFactory.getModel({type: "text", options:{
                text: `Read`,
                color:  modelFactory.getModel({type: "color", options:{
                  start: "quaternary-500",
                }}),
              }}),
              description: modelFactory.getModel({type: "text", options:{
                text: `Writing is a great way to express your thoughts. You can find my writing on my blog but also in 
                external platforms like Medium, Dev.To and Hashnode`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "neutral-500"
                }})
              }}),
              icon: modelFactory.getModel({type: "icon", options:{
                name: "hero_checkin",
                size: {
                  height: 10
                },
                className: "ml-2 mt-5",
                color: modelFactory.getModel({type: "color", options: {
                  start: "white"
                }})
              }}),
            }}),
            modelFactory.getModel({type: "feature_item_item", options: {
              id: 2,
              name: modelFactory.getModel({type: "text", options:{
                text: `Watch`,
                color:  modelFactory.getModel({type: "color", options:{
                  start: "quaternary-500",
                }}),
              }}),
              description: modelFactory.getModel({type: "text", options:{
                text: `Creating videos is fun and a great way to share my experience. You can find my videos on Youtube and TikTok!`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "neutral-500"
                }})
              }}),
              icon: modelFactory.getModel({type: "icon", options:{
                name: "hero_checkin",
                size: {
                  height: 10
                },
                className: "ml-2 mt-5",
                color: modelFactory.getModel({type: "color", options: {
                  start: "white"
                }})
              }}),
            }}),
            modelFactory.getModel({type: "feature_item_item", options: {
              id: 3,
              name: modelFactory.getModel({type: "text", options:{
                text: `Listen`,
                color:  modelFactory.getModel({type: "color", options:{
                  start: "quaternary-500",
                }}),
              }}),
              description: modelFactory.getModel({type: "text", options:{
                text: `Audio is a great format to share and learn new content. My podcast is coming soon...`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "neutral-500"
                }})
              }}),
              icon: modelFactory.getModel({type: "icon", options:{
                name: "hero_checkin",
                size: {
                  height: 10
                },
                className: "ml-2 mt-5",
                color: modelFactory.getModel({type: "color", options: {
                  start: "white"
                }})
              }}),
            }})
          ]
        }
      })
    ]
  }}),
  featuresTestimonial: modelFactory.getModel({type: "feature", options:{
    background: {
      color: modelFactory.getModel({type: "text", options: {
        start: "white"
      }})
    },
    heading: modelFactory.getModel({type: "text", options:{
      text: "ONLINE CONTENT",
      color:  modelFactory.getModel({type: "color", options:{
        start: "quaternary-500"
      }}),
    }}),
    subheading: modelFactory.getModel({type: "text", options:{
      text: `Some of the content that I have created in the past`,
      color: modelFactory.getModel({type: "color", options:{
        start: "neutral-500"
      }}),
    }}),
    sections: [
      modelFactory.clone(featureItem, {
        media: {
          type: "video",
          image: modelFactory.getModel({type: "image", options: {
            src: "https://raw.githubusercontent.com/domenicosolazzo/images/main/images/youtube/automatevideos.png",
          }})
        },
        heading: modelFactory.getModel({type: "text", options: {
          text: "Automate Videos using Python",
          color:  modelFactory.getModel({type: "color", options:{
            gradient: true,
            start: "primary-500",
            end: "secondary-500"
          }}),
        }}),
        icon: modelFactory.getModel({type: "icon", options:{
          name: "youtube",
          size: {
            height: 30
          },
          className: "ml-2 mt-5",
          color: modelFactory.getModel({type: "color", options: {
            start: "white"
          }})
        }}),
        subheading: modelFactory.getModel({type: "text", options: {
          text: `Few lines of Python Code and you can do great things with video production.`,
          color: modelFactory.getModel({type: "color", options: {
            start: "neutral-500"
          }})
        }}),
        testimonial: null,
        actions: [
          {
            key:1,
            link: modelFactory.clone(DataLinks.videos.youtube.automate_videos_with_python, {linkText: "Check this out on Youtube"})
          }
        ],
        itemList: null
      }),
      modelFactory.clone(featureItem, {
        heading: modelFactory.getModel({type: "text", options: {
          text: "Tools that I use",
          color:  modelFactory.getModel({type: "color", options:{
            gradient: true,
            end: "primary-500",
            start: "secondary-500"
          }}),
        }}),
        icon: modelFactory.getModel({type: "icon", options:{
          name: "instagram",
          size: {
            height: 30
          },
          className: "ml-2 mt-5",
          color: modelFactory.getModel({type: "color", options: {
            start: "white"
          }})
        }}),
        subheading: modelFactory.getModel({type: "text", options: {
          text: `Let me tell you the tools that I use daily during my job.`,
          color: modelFactory.getModel({type: "color", options: {
            start: "neutral-500"
          }})
        }}),
        media: {
          image: modelFactory.getModel({type: "image", options: {
            src: "https://raw.githubusercontent.com/domenicosolazzo/images/main/images/instagram/toolsIUse.png"
          }}),
          
        },
        testimonial: null,
        actions: [
          {
            key:1,
            link: modelFactory.clone(DataLinks.instagram_posts.tools_i_use_everyday, {linkText: "Check this out on Instagram"})
          }
        ],
        itemList: null,
        metadata: {
          reverse: true
        }
      }),
      modelFactory.clone(featureItem, {
        heading: modelFactory.getModel({type: "text", options: {
          text: "Help Junior Developers",
          color:  modelFactory.getModel({type: "color", options:{
            gradient: true,
            start: "primary-500",
            end: "secondary-500"
          }}),
        }}),
        icon: modelFactory.getModel({type: "icon", options:{
          name: "devto",
          size: {
            height: 30
          },
          className: "ml-2 mt-5",
          color: modelFactory.getModel({type: "color", options: {
            start: "white"
          }})
        }}),
        subheading: modelFactory.getModel({type: "text", options: {
          text: `We should always be helping junior developers and helping them to grow in their career.`,
          color: modelFactory.getModel({type: "color", options: {
            start: "neutral-500"
          }})
        }}),
        media: {
          image: modelFactory.getModel({type: "image", options: {
            src: "https://raw.githubusercontent.com/domenicosolazzo/images/main/images/blogs/helpdevelopers.png"
          }})
        },
        testimonial: null,
        actions: [
          {
            key:1,
            link: modelFactory.clone(DataLinks.blog_posts.help_junior_developer, {linkText: "Check this out in the blog"})
          }
        ],
        itemList: null,
        metadata: {
          reverse: false
        }
      }),
    ]
  }}),
  testimonial: modelFactory.getModel({ type: "testimonial_overalapping_image", options: {
    message: modelFactory.getModel({type: "text", options: {
      text: `Domenico, great content! Don't stop!.
      `,
      color: modelFactory.getModel({type: "color", options: {
        start: "white"
      }})
    }}),
    background:{
      color: modelFactory.getModel({type: "color", options: {
        start: "primary-500",
        metadata: {
          property: "bg"
        }
      }})
    },  
    image:modelFactory.getModel({type: "image", options: {
      src: "https://images.unsplash.com/photo-1546146830-2cca9512c68e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2102&q=80"
    }}),
    author: {
      name: modelFactory.getModel({type: "text", options: {
        text: "Matt M.",
        color: modelFactory.getModel({type: "color", options: {
          start: "white"
        }})
      }}),
      jobTitle: modelFactory.getModel({type: "text", options: {
        text: "Youtube Fan",
        color: modelFactory.getModel({type: "color", options: {
          start: "white"
        }})
      }}),
    }
  }}),
  contact: ContactData.getContactInfo({
    items: [
      {title: "Contact", email: `${DataLinks.emails.contact}`, message: "Do you want to work with me, hire me or do you just want to have a chat? Contact me to the email below"},
      {title: "Collaborate", email: `${DataLinks.emails.business}`, message: "Do you want to have a collaboration with me on Youtube, Facebook, Instagram or TikTok?"}
    ]
  }) 
}

export default PageData