import React from "react"
import { modelFactory } from "@domenicosolazzo/site-designsystem"
import { DataLinks } from "../../.."

const PrivacyPolicyPage = {
  hero: modelFactory.getModel({type: "hero_with_media", options: {
    heading: modelFactory.getModel({type: "text", options: {
      text: "Cookie & Privacy",
      color:  modelFactory.getModel({type: "color", options:{
        gradient: true,
        start: "primary-500",
        end: "secondary-500"
      }}),
      wrapper:{
        type:"div",
        className: "",
        hidden: false,
      },
      metadata: {
        type: "p"
      }
    }}),
    subheading: modelFactory.getModel({type: "text", options: {
      text: (
        <div className="mt-6 mb-56">
          <div className="mb-2">Both our privacy and cookie policy can be found on the link below.</div>
          <br />
          <div>
            <a href="https://www.iubenda.com/privacy-policy/61536211" 
              className="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " 
              title="Privacy Policy ">Read our Privacy Policy</a>
            <a href="https://www.iubenda.com/privacy-policy/61536211/cookie-policy" className="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe "  title="Cookie Policy">
              Read our Cookie Policy
            </a>
          </div>
          
        </div>
      ),
      color: modelFactory.getModel({type: "color", options: {
        start: "neutral-500"
      }}),
    }}),
    actions: null,
    media: {
      image: modelFactory.getModel({type: "image", options: {
        src: "https://images.unsplash.com/photo-1569396116180-210c182bedb8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2338&q=80"  
      }})
    }
  }})
  
}

export default PrivacyPolicyPage