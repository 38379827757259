import {
  modelFactory
} from "@domenicosolazzo/site-designsystem"
import DataLinks from "../links"

const errorNotFound = {
  error:modelFactory.getModel({type: "error", options: {
    code: modelFactory.getModel({type: "text", options: { text: "404"}}),
    status: modelFactory.getModel({type: "text", options: { text: "Error 404"}}),
    title: modelFactory.getModel({type: "text", options: { text: "Page not found"}}),
    message: modelFactory.getModel({type: "text", options: { text: "Sorry, we couldn’t find the page you’re looking for."}}),
  }}),
  link:modelFactory.clone(DataLinks.home, {
    linkText: "Go to the Homepage",
    linkValue: "/",
    className: "px-0",
  }),
  image:modelFactory.getModel({type: "image", options:{
    src:"https://images.unsplash.com/photo-1470847355775-e0e3c35a9a2c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1825&q=80",
    className: "absolute inset-0 h-full w-full object-cover"
  }})
}

export default errorNotFound