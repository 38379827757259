import React from "react"
import { modelFactory } from "@domenicosolazzo/site-designsystem"
import { DataLinks } from "../../../../"

const data = modelFactory.getModel({type: "logo_cloud", options:{
  heading: modelFactory.getModel({type: "text", options:{
    text: "Where you can find me online",
    color:  modelFactory.getModel({type: "color", options:{
      start: "white"
    }}),
    className: "text-xl lg:text-4xl"
  }}),
  background: modelFactory.getModel({type: "color", options: { 
    gradient: true,
    start: "primary-500",
    end: "primary-500",
    metadata: {
      property: "bg"
    }
  }}),
  logos: [
    {
      image: modelFactory.getModel({type: "icon", options: {
        icon: {
          name: "fa-instagram",
          brand: true,
          color: "white",
          size: "4x",
          className: "mt-1 ml-4"
        }, size: {height: 60, width: 60}}}),
      link: DataLinks.instagram
    },
    {
      image: modelFactory.getModel({type: "icon", options: {
        icon: {
          name: "fa-facebook",
          brand: true,
          color: "white",
          size: "4x",
          className: "mt-1 ml-4"
        }, size: {height: 60, width: 60}}}),
      link: DataLinks.facebook
    },
    {
      image: modelFactory.getModel({type: "icon", options: {
        icon: {
          name: "fa-youtube",
          brand: true,
          color: "white",
          size: "4x",
          className: "mt-1 ml-4"
        }, size: {height: 60, width: 60}}}),
      link: DataLinks.youtube
    },
    {
      image: modelFactory.getModel({type: "icon", options: {
        icon: {
          name: "fa-medium",
          brand: true,
          color: "white",
          size: "4x",
          className: "mt-1 ml-4"
        },size: {height: 60, width: 60}}}),
      link: DataLinks.medium
    },
    {
      image: modelFactory.getModel({type: "icon", options: {
        icon: {
          name: "fa-dev",
          brand: true,
          color: "white",
          size: "4x",
          className: "mt-1 ml-4"
        },size: {height: 60, width: 60}}}),
      link: DataLinks.devto
    },
    {
      image: modelFactory.getModel({type: "icon", options: {
        icon: {
          name: "fa-github",
          brand: true,
          color: "white",
          size: "4x",
          className: "mt-1 ml-4"
        },size: {height: 60, width: 60}}}),
      link: DataLinks.github
    },
    {
      image: modelFactory.getModel({type: "icon", options: {
        icon: {
          name: "fa-linkedin",
          brand: true,
          color: "white",
          size: "4x",
          className: "mt-1 ml-4"
        },size: {height: 60, width: 60}}}),
      link: DataLinks.linkedin
    },
    {
      image: modelFactory.getModel({type: "icon", options: {
        icon: {
          name: "fa-twitter",
          brand: true,
          color: "white",
          size: "4x",
          className: "mt-1 ml-4"
        },size: {height: 60, width: 60}}}),
      link: DataLinks.twitter
    }
  ]
}})
const PageData = {
  ...data
}

export default PageData