import React from "react"
import { modelFactory } from "@domenicosolazzo/site-designsystem"
import { DataLinks } from "../../.."
import { ContactData } from "../../components"

const featureItem = modelFactory.getModel({type: "feature_item", options:{}})

const PageData = {
  hero:modelFactory.getModel({type: "hero_with_media", options: {
    slogan: modelFactory.getModel({type: "text", options: {
      text: "Software engineer"
    }}),
    heading: modelFactory.getModel({type: "text", options: {
      text: "NO BAD TEAMS, ONLY BAD LEADERS.",
      color: modelFactory.getModel({type: "color", options: {
        gradient: true,
        start: "primary-500",
        end: "secondary"
      }}),
      wrapper:{
        type:"div",
        className: "",
        hidden: false,
      },
      metadata: {
        type: "p"
      }
    }}),
    background: {
      color: modelFactory.getModel({type:"color", options: {start: "white", metadata: {property: "bg"}}})
    },
    subheading: modelFactory.getModel({type: "text", options: {
      text: `Leadership experience and focus on creating high-performant teams focused on delivery on-time and quality.
      Bad teams do not exist, only bad leaders. That's why you need a person like me to make the best of your team.`,
      color: modelFactory.getModel({type: "color", options: {
        start: "neutral-500"
      }}),
    }}),
    actions: null,
    media: {
      image: modelFactory.getModel({type: "image", options: {
        src: "https://images.unsplash.com/photo-1507679799987-c73779587ccf?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1951&q=80"  
      }})
    }
  }}),
  logoCloud:modelFactory.getModel({type:"logo_cloud", options: {
  
      heading: modelFactory.getModel({type: "text", options:{
        text: "HIGH-PERFORMANT TEAMS FOCUSED ON QUALITY, TIME AND CULTURE",
        color:  modelFactory.getModel({type: "color", options:{
          start: "white"
        }}),
      }}),
      background: modelFactory.getModel({type: "color", options: { 
        gradient: true,
        start: "primary-500",
        end: "primary-500",
        metadata: {
          property: "bg"
        }
      }}),
      logos: [
        {
          image: modelFactory.getModel({type: "icon", options: {icon: {name: "fa-user-tie", size: "5x", color: "white"}, size: {height: 20, width: 20}}}),
          link: null
        },
        {
          image: modelFactory.getModel({type: "icon", options: {icon: {name: "fa-code", size: "5x", color: "white"}, size: {height: 20, width: 20}}}),
          link: null
        },
        {
          image: modelFactory.getModel({type: "icon", options: {icon: {name: "fa-tachometer-alt", size: "5x", color: "white"}, size: {height: 20, width: 20}}}),
          link: null
        },
        {
          image: modelFactory.getModel({type: "icon", options: {icon: {name: "fa-medal", size: "5x", color: "white"}, size: {height: 20, width: 20}}}),
          link: null
        },
      ]
    }
  }),
  features: modelFactory.getModel({type: "feature", options:{
    background: {
      color: modelFactory.getModel({type: "text", options: {
        start: "white"
      }})
    },
    heading: modelFactory.getModel({type: "text", options:{
      text: "ENGINEERING MANAGER",
      color:  modelFactory.getModel({type: "color", options:{
        start: "quaternary-500"
      }}),
    }}),
    subheading: modelFactory.getModel({type: "text", options:{
      text: `I have been leading software development teams in the past years as well as helped the teams with engineering expertise`,
      color: modelFactory.getModel({type: "color", options:{
        start: "neutral-500"
      }}),
    }}),
    sections: [
      modelFactory.clone(featureItem, {
        media: {
          image: modelFactory.getModel({type: "image", options: {
            src: "https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1600&q=80"
          }})
        },
        heading: modelFactory.getModel({type: "text", options: {
          text: "ENGINEERING MANAGER",
          color:  modelFactory.getModel({type: "color", options:{
            gradient: true,
            start: "primary-500",
            end: "secondary-500"
          }}),
        }}),
        subheading: modelFactory.getModel({type: "text", options: {
          text: `Helpe teams to build the right product with the appropriate software architecture, tools and methodologies`,
          color: modelFactory.getModel({type: "color", options: {
            start: "neutral-500"
          }})
        }}),
        itemList: {
          items: [
            modelFactory.getModel({type: "feature_item_item", options: {
              id: 1,
              name: modelFactory.getModel({type: "text", options:{
                text: `Engineering Experience`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "quaternary-500"
                }})
              }}),
              description: modelFactory.getModel({type: "text", options:{
                text: `I have software engineering experience from working on different kind of products in the past years`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "neutral-500"
                }})
              }}),
              icon: modelFactory.getModel({type: "icon", options:{
                name: "hero_checkin",
                size: {
                  height: 10
                },
                className: "ml-2 mt-5",
                color: modelFactory.getModel({type: "color", options: {
                  start: "white"
                }})
              }}),
            }}),
            modelFactory.getModel({type: "feature_item_item", options: {
              id: 2,
              name: modelFactory.getModel({type: "text", options:{
                text: `Software Architecture & Tools`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "quaternary-500"
                }})
              }}),
              description: modelFactory.getModel({type: "text", options:{
                text: `Helping teams to choose the appropriate tools and architecture for the product they need to build.`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "neutral-500"
                }})
              }}),
              icon: modelFactory.getModel({type: "icon", options:{
                name: "hero_checkin",
                size: {
                  height: 10
                },
                className: "ml-2 mt-5",
                color: modelFactory.getModel({type: "color", options: {
                  start: "white"
                }})
              }}),
            }}),
            modelFactory.getModel({type: "feature_item_item", options: {
              id: 3,
              name: modelFactory.getModel({type: "text", options:{
                text: `Methodologies`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "quaternary-500"
                }}) 
              }}),
              description: modelFactory.getModel({type: "text", options:{
                text: `One of the most important factor for high-performant teams is to use the right methodologies while building the software product.`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "neutral-500"
                }})
              }}),
              icon: modelFactory.getModel({type: "icon", options:{
                name: "hero_checkin",
                size: {
                  height: 10
                },
                className: "ml-2 mt-5",
                color: modelFactory.getModel({type: "color", options: {
                  start: "white"
                }})
              }}),
            }})
          ]
        }
      }),
      modelFactory.clone(featureItem, {
        heading: modelFactory.getModel({type: "text", options: {
          text: "LEADERSHIP",
          color:  modelFactory.getModel({type: "color", options:{
            gradient: true,
            end: "primary-500",
            start: "secondary-500"
          }}),
        }}),
        subheading: modelFactory.getModel({type: "text", options: {
          text: `Teams are a group of human being. Inspiring, motivating and helping them to grow in the right career path is an important duty for any leader in the industry.`,
          color: modelFactory.getModel({type: "color", options: {
            start: "neutral-500"
          }})
        }}),
        media: {
          image: modelFactory.getModel({type: "image", options: {
            src: "https://tailwindui.com/img/features/feature-example-1.png"
          }})
        },
        itemList: {
          items: [
            modelFactory.getModel({type: "feature_item_item", options: {
              id: 1,
              name: modelFactory.getModel({type: "text", options:{
                text: `Mentorship`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "quaternary-500"
                }})
              }}),
              description: modelFactory.getModel({type: "text", options:{
                text: `Working in tech is exciting but also challenging. 
                Having a good mentor can really make a difference in your team.`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "neutral-500"
                }})
              }}),
              icon: modelFactory.getModel({type: "icon", options:{
                name: "hero_checkin",
                size: {
                  height: 10
                },
                className: "ml-2 mt-5",
                color: modelFactory.getModel({type: "color", options: {
                  start: "white"
                }})
              }}),
            }}),
            modelFactory.getModel({type: "feature_item_item", options: {
              id: 2,
              name: modelFactory.getModel({type: "text", options:{
                text: `Career Development`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "quaternary-500"
                }})
              }}),
              description: modelFactory.getModel({type: "text", options:{
                text: `Each team member should have their personalized career path and that's where I can help them.`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "neutral-500"
                }})
              }}),
              icon: modelFactory.getModel({type: "icon", options:{
                name: "hero_checkin",
                size: {
                  height: 10
                },
                className: "ml-2 mt-5",
                color: modelFactory.getModel({type: "color", options: {
                  start: "white"
                }})
              }}),
            }}),
            modelFactory.getModel({type: "feature_item_item", options: {
              id: 3,
              name: modelFactory.getModel({type: "text", options:{
                text: `Performance`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "quaternary-500"
                }})
              }}),
              description: modelFactory.getModel({type: "text", options:{
                text: `Helping the team to perform at their best and improve if they have difficulties to reach their best standard.`,
                color: modelFactory.getModel({type: "color", options: {
                  start: "neutral-500"
                }})
              }}),
              icon: modelFactory.getModel({type: "icon", options:{
                name: "hero_checkin",
                size: {
                  height: 10
                },
                className: "ml-2 mt-5",
                color: modelFactory.getModel({type: "color", options: {
                  start: "white"
                }})
              }}),
            }})
          ]
        }
      })
    ]
  }}),
  featuresTestimonial: modelFactory.getModel({type: "feature", options:{
    background: {
      color: modelFactory.getModel({type: "text", options: {
        start: "white"
      }})
    },
    heading: modelFactory.getModel({type: "text", options:{
      text: "HIGH-PERFORMANT TEAMS",
      color:  modelFactory.getModel({type: "color", options:{
        start: "quaternary-500"
      }}),
    }}),
    subheading: modelFactory.getModel({type: "text", options:{
      text: `High-performant teams can really improve the quality and timing of your deliveries while creating a great engineering culture in your company`,
      color: modelFactory.getModel({type: "color", options:{
        start: "neutral-500"
      }}),
    }}),
    sections: [
      modelFactory.clone(featureItem, {
        media: {
          image: modelFactory.getModel({type: "image", options: {
            src: "https://raw.githubusercontent.com/domenicosolazzo/images/main/images/okr/okr.png"
          }})
        },
        heading: modelFactory.getModel({type: "text", options: {
          text: "OKR",
          color:  modelFactory.getModel({type: "color", options:{
            gradient: true,
            start: "primary-500",
            end: "secondary-500"
          }}),
        }}),
        icon: modelFactory.getModel({type: "icon", options:{
          name: "hero_checkin",
          size: {
            height: 10
          },
          className: "ml-2 mt-5",
          color: modelFactory.getModel({type: "color", options: {
            start: "white"
          }})
        }}),
        subheading: modelFactory.getModel({type: "text", options: {
          text: `Your teams needs the right goalsto reach their best performance in your company. OKRs are a great way to setup goals in your company and help 
          your teams to align to the company vision.`,
          color: modelFactory.getModel({type: "color", options: {
            start: "neutral-500"
          }})
        }}),
        testimonial: null,
        actions: null,
        itemList: null
      }),
      modelFactory.clone(featureItem, {
        heading: modelFactory.getModel({type: "text", options: {
          text: "DORA METRICS",
          color:  modelFactory.getModel({type: "color", options:{
            gradient: true,
            end: "primary-500",
            start: "secondary-500"
          }}),
        }}),
        icon: modelFactory.getModel({type: "icon", options:{
          name: "hero_checkin",
          size: {
            height: 10
          },
          className: "ml-2 mt-5",
          color: modelFactory.getModel({type: "color", options: {
            start: "white"
          }})
        }}),
        subheading: modelFactory.getModel({type: "text", options: {
          text: `They help DevOps and engineering leaders measure software delivery throughput (velocity) and stability (quality). 
          They show how development teams can deliver better software to their customers, faster.`,
          color: modelFactory.getModel({type: "color", options: {
            start: "neutral-500"
          }})
        }}),
        media: {
          image: modelFactory.getModel({type: "image", options: {
            src: "https://raw.githubusercontent.com/domenicosolazzo/images/main/images/metrics/dora.png"
          }})
        },
        testimonial: null,
        actions: null,
        itemList: null,
        metadata: {
          reverse: true
        }
      }),
    ]
  }}),
  testimonial: modelFactory.getModel({ type: "testimonial_overalapping_image", options: {
    message: modelFactory.getModel({type: "text", options: {
      text: `Having Domenico as my manager was a great experience. 
      He always made sure I had enough work to do and was available to answer any questions I had. 
      He is a very talented manager who has the leadership to take a team to its best, as well as the communication needed to transmit ideas to his colleagues.
      `,
      color: modelFactory.getModel({type: "color", options: {
        start: "white"
      }})
    }}),
    background:{
      color: modelFactory.getModel({type: "color", options: {
        start: "primary-500",
        metadata: {
          property: "bg"
        }
      }})
    },  
    image:modelFactory.getModel({type: "image", options: {
      src: "https://images.unsplash.com/photo-1546146830-2cca9512c68e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2102&q=80"
    }}),
    author: {
      name: modelFactory.getModel({type: "text", options: {
        text: "Alejandro C.",
        color: modelFactory.getModel({type: "color", options: {
          start: "quaternary-500"
        }})
      }}),
      jobTitle: modelFactory.getModel({type: "text", options: {
        text: "Senior Software Engineer",
        color: modelFactory.getModel({type: "color", options: {
          start: "quaternary-500"
        }})
      }}),
    }
  }}),
  contact: ContactData.getContactInfo({
    items: [
      {title: "Contact", email: `${DataLinks.emails.contact}`, message: "Do you want to work with me, hire me or do you just want to have a chat? Contact me to the email below"},
      {title: "Collaborate", email: `${DataLinks.emails.business}`, message: "Do you want to have a collaboration with me on Youtube, Facebook, Instagram or TikTok?"}
    ]
  }) 
}

export default PageData