import { modelFactory } from "@domenicosolazzo/site-designsystem";
import { DataLinks } from "../../..";

const instagram = modelFactory.getModel({type: "footer_navigation_item", options: {
  name: "Instagram",
  link: DataLinks.instagram,
  media: modelFactory.getModel({type: "media", options: {
    image: modelFactory.getModel({type: "icon", options: {
      icon: {name: "fa-instagram", brand: true, size: "2x", color: "#f47b3e"},
    }})
  }})
}})

const facebook = modelFactory.getModel({type: "footer_navigation_item", options: {
  name: "Facebook",
  link: DataLinks.facebook,
  media: modelFactory.getModel({type: "media", options: {
    image: modelFactory.getModel({type: "icon", options: {
      icon: {name: "fa-facebook", brand: true, size: "2x", color: "#f47b3e"},
    }})
  }})
}})

const youtube = modelFactory.getModel({type: "footer_navigation_item", options: {
  name: "Youtube",
  link: DataLinks.youtube,
  media: modelFactory.getModel({type: "media", options: {
    image: modelFactory.getModel({type: "icon", options: {
      icon: {name: "fa-youtube", brand: true, size: "2x", color: "#f47b3e"},
    }})
  }})
}})

const github = modelFactory.getModel({type: "footer_navigation_item", options: {
  name: "Github",
  link: DataLinks.github,
  media: modelFactory.getModel({type: "media", options: {
    image: modelFactory.getModel({type: "icon", options: {
      icon: {name: "fa-github", brand: true, size: "2x", color: "#f47b3e"},
    }})
  }})
}})

const twitter = modelFactory.getModel({type: "footer_navigation_item", options: {
  name: "Twitter",
  link: DataLinks.twitter,
  media: modelFactory.getModel({type: "media", options: {
    image: modelFactory.getModel({type: "icon", options: {
      icon: {name: "fa-twitter", brand: true, size: "2x", color: "#f47b3e"},
    }})
  }})
}})

const linkedin = modelFactory.getModel({type: "footer_navigation_item", options: {
  name: "Linkedin",
  link: DataLinks.linkedin,
  media: modelFactory.getModel({type: "media", options: {
    image: modelFactory.getModel({type: "icon", options: {
      icon: {name: "fa-linkedin", brand: true, size: "2x", color: "#f47b3e"},
    }})
  }})
}})


const home = modelFactory.getModel({type: "footer_navigation_item", options: {
  name: "Home",
  link: modelFactory.getModel({type: "link", options: {
    url: "/"
  }}),
}}) 

const contact = modelFactory.getModel({type: "footer_navigation_item", options: {
  name: "Contact",
  link: modelFactory.getModel({type: "link", options: {
    url: "/contact"
  }}),
}}) 

const blog = modelFactory.getModel({type: "footer_navigation_item", options: {
  name: "Blog",
  link: modelFactory.getModel({type: "link", options: {
    url: "/blog"
  }}),
}}) 

const developer = modelFactory.getModel({type: "footer_navigation_item", options: {
  name: "Software Engineer",
  link: modelFactory.getModel({type: "link", options: {
    url: "/developer"
  }}),
}}) 


const manager = modelFactory.getModel({type: "footer_navigation_item", options: {
  name: "Engineering Manager",
  link: modelFactory.getModel({type: "link", options: {
    url: "/leadership"
  }}),
}}) 

const creator = modelFactory.getModel({type: "footer_navigation_item", options: {
  name: "Content Creator",
  link: modelFactory.getModel({type: "link", options: {
    url: "/creator"
  }}),
}}) 

const communityBuilder = modelFactory.getModel({type: "footer_navigation_item", options: {
  name: "Community Builder",
  link: modelFactory.getModel({type: "link", options: {
    url: "/community_builder"
  }}),
}}) 

const privacyPolicy = modelFactory.getModel({type: "footer_navigation_item", options: {
  name: "Privacy Policy",
  link: DataLinks.privacy
}})


const social = [
  instagram,
  facebook,
  youtube,
  linkedin,
  github,
  twitter,
]

const navigation = [
  home,
  // manager,
  // developer,
  //creator,
  // communityBuilder,
  blog,
  contact,
  privacyPolicy
]

const footer = modelFactory.getModel({type: "footer", options: {
  social: social,
  navigation: navigation,
}});

export default footer;