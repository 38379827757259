import React from "react"
import { modelFactory } from "@domenicosolazzo/site-designsystem"
import { DataLinks } from "../../.."
import { ContactData } from "../../components"

const ContactPage = {
  hero: modelFactory.getModel({type: "hero_with_media", options: {
    heading: modelFactory.getModel({type: "text", options: {
      text: "DO YOU WANT TO WORK WITH ME?",
      color:  modelFactory.getModel({type: "color", options:{
        gradient: true,
        start: "primary-500",
        end: "secondary-500"
      }}),
      wrapper:{
        type:"div",
        className: "",
        hidden: false,
      },
      metadata: {
        type: "p"
      }
    }}),
    subheading: modelFactory.getModel({type: "text", options: {
      text: "If you are in need of a professional Engineering Manager or would like to collaborate online, contact me today!",
      color: modelFactory.getModel({type: "color", options: {
        start: "neutral-500"
      }}),
    }}),
    actions: null,
    media: {
      image: modelFactory.getModel({type: "image", options: {
        src: "https://images.unsplash.com/photo-1532619675605-1ede6c2ed2b0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"  
      }})
    }
  }}),
  faq: {
    background: {
        color: modelFactory.getModel({type:"color", options: {
          start: "quaternary-500",
          metadata: { 
            property: "bg"
          }
        }})
    },
    heading: modelFactory.getModel({type: "text", options: {
      text: `Frequently asked questions`,
      color: modelFactory.getModel({type: "color", options: {start: 'primary-500'}})
    }}),
    subheading: modelFactory.getModel({type: "text", options: {
      text: `Some of the questions that I get daily in my mailbox`,
      color: modelFactory.getModel({type: "color", options: {start: 'primary-100'}})
    }}),
    faqs: [
      {
        id: 1,
        question: modelFactory.getModel({type: "text", options: {
          text: "How can you help me?",
          color: modelFactory.getModel({type: "color", options: {
            start: "primary-500"
          }})
        }}),
        answer: modelFactory.getModel({type: "text", options: {
          text: `If you need someone that could lead, motivate and improve the deliveries and quality of your team,
          I can be the person that can help you`,
          color: modelFactory.getModel({type:"color", options: {
            start: "gray-100"
          }})
        }})
      },
      {
        id: 2,
        question: modelFactory.getModel({type: "text", options: {
          text: "Can you help me to build my next digital solution?",
          color: modelFactory.getModel({type: "color", options: {
            start: "primary-500"
          }})
        }}),
        answer: modelFactory.getModel({type: "text", options: {
          text: `Yes, I can. `,
          color: modelFactory.getModel({type:"color", options: {
            start: "gray-100"
          }})
        }})
      },
      {
        id: 3,
        question: modelFactory.getModel({type: "text", options: {
          text: "Which kind of collaboration are you available for?",
          color: modelFactory.getModel({type: "color", options: {
            start: "primary-500"
          }})
        }}),
        answer: modelFactory.getModel({type: "text", options: {
          text: `Content creation collaborations, brand sponsorships`,
          color: modelFactory.getModel({type:"color", options: {
            start: "gray-100"
          }})
        }})
      },
      {
        id: 3,
        question: modelFactory.getModel({type: "text", options: {
          text: "Where should I contact you for brand sponsorship?",
          color: modelFactory.getModel({type: "color", options: {
            start: "primary-500"
          }})
        }}),
        answer: modelFactory.getModel({type: "text", options: {
          text: `Use the collaboration email below.`,
          color: modelFactory.getModel({type:"color", options: {
            start: "neutral-500"
          }})
        }})
      },
    ]
  },
  contact: ContactData.getContactInfo({
    items: [
      {title: "Contact", email: `${DataLinks.emails.contact}`, message: "Do you want to work with me, hire me or do you just want to have a chat? Contact me to the email below"},
      {title: "Collaborate", email: `${DataLinks.emails.business}`, message: "Do you want to have a collaboration with me on Youtube, Facebook, Instagram or TikTok?"}
    ]
  }) 
}

export default ContactPage